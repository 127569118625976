const RouteConstants = {
  ROUTES: {
    LOGIN: "/login",
    PLANNING: "/standard/planning",
    RECONCILIATION: "/reconciliation",
    PERFORMANCE: "/standard/performance-dashboard",
    CREATE_ROUTE: "/create-route",
    EDIT_ROUTE: "/edit-route",
    VIEW_ROUTE: "/view-route",
    RESET_PWD: "/reset-pwd",
    USERS: "/users",
    INDEX: "/",
    HOME: "/standard/planning",
    TRACKING: "/standard/tracking",
    CLICKNCOLLECT: "/other-deliveries/click-and-collect",
    VALETTROLLEY: "/other-deliveries/valet-trolley",
    CARREFOUR_HOME_DLVRY: "/other-deliveries/home-delivery",
    EXPRESS: "/standard/express-leb",
    CARREFOUR_NOW: "/express-delivery/express",
    DASHBOARD: "/dashboard",
    HUBS: "/standard/hubs",
    POS: "/express-delivery/pos",
    FLEET: "/fleet",
    DRIVERS: "/express-delivery/drivers",
    ADMINISTRATION: "/administration",
    STD_COD_PORTAL: "/standard/cod-reconciliation",
    ALL_ORDERS: "/all-orders/deliveries",
    RETURNS: "/all-orders/returns",
    DASHBOARDCAMS: "/all-orders/C4dashboard",
    EXCEPTIONS_DELIVERIES: "/all-orders/exceptions-deliveries",
    STAGING_AREA: "/administration/staging-area",
    CUSTOMER_COMMUNICATION: "/administration/communication",
    HUBWISE: "/standard/hub-wise",
    COD_PORTAL: "/express-delivery/cod-reconciliation",
    VALET_TROLLEY_CREATE_ROUTE: "/valet-trolley_create-route",
    VALET_TROLLEY_EDIT_ROUTE: "/valet-trolley_edit-route",
    VALET_TROLLEY_VIEW_ROUTE: "/valet-trolley_view-route",
    CARREFOUR_DELIVERY_CREATE_ROUTE: "/home-delivery_create-route",
    CARREFOUR_DELIVERY_EDIT_ROUTE: "/home-delivery_edit-route",
    CARREFOUR_DELIVERY_VIEW_ROUTE: "/home-delivery_view-route",
    DELVE_DASHBOARD: "/delve/dashboard",
    DELVE_ORDER_LIST: "/delve/order-list",
    STANDARD: "/standard",
    EXPRESS_PAGE: "/express",
    STORE_MANAGEMENT: "/store-management",
    //
    DELIVERIES_GROUP_ID: "deliveries",
    DELIVERIES_GROUP: [
      {
        route: "/standard/planning",
        isListItem: true,
      },
      {
        route: "/create-route",
        isListItem: false,
      },
      {
        route: "/edit-route",
        isListItem: false,
        hasParams: true,
      },
    ],
    DASHBOARD_GROUP_ID: "dashboard",
    DASHBOARD_GROUP: [
      {
        route: "/performance",
        isListItem: true,
      },
      {
        route: "/reconciliation",
        isListItem: true,
      },
    ],
    HUB_GROUP_ID: "hubs",
    HUB_GROUP: [
      {
        route: "/standard/hubs",
        isListItem: true,
      },
      {
        route: "/pos",
        isListItem: true,
      },
    ],
    ADMINISTRATION_GROUP_ID: "administration",
    ADMINISTRATION_GROUP: [
      {
        route: "/administration/staging-area",
        isListItem: true,
      },
      {
        route: "/administration/communication",
        isListItem: true,
      },
    ],
    ALL_ORDERS_GROUP_ID: "all-orders",
    ALL_ORDERS_GROUP: [
      {
        route: "/all-orders/deliveries",
        isListItem: true,
      },
      {
        route: "/all-orders/returns",
        isListItem: true,
      },
      {
        route: "/all-orders/C4dashboard",
        isListItem: true,
      },
      {
        route: "/all-orders/exceptions-deliveries",
        isListItem: true,
      },
    ],
    VT_CONFIG: {
      Routes: {
        VALETTROLLEY: "/other-deliveries/valet-trolley",
        Hubs: "/other-deliveries/hubs",
        Tracking: "/other-deliveries/tracking",
      },
      VT_GROUP_ID: "other-deliveries",
      VT_GROUP: [
        {
          route: "/other-deliveries/valet-trolley",
          isListItem: true,
        },
        {
          route: "/other-deliveries/hubs",
          isListItem: true,
        },
        {
          route: "/other-deliveries/tracking",
          isListItem: true,
        },
      ],
    },
    CNC_CONFIG: {
      Routes: {
        CLICKNCOLLECT: "/other-deliveries/click-and-collect",
        Hubs: "/other-deliveries/hubs",
        Tracking: "/other-deliveries/tracking",
      },
      CNC_GROUP_ID: "other-deliveries",
      CNC_GROUP: [
        {
          route: "/other-deliveries/click-and-collect",
          isListItem: true,
        },
        {
          route: "/other-deliveries/hubs",
          isListItem: true,
        },
        {
          route: "/other-deliveries/tracking",
          isListItem: true,
        },
      ],
    },
    CHD_CONFIG: {
      Routes: {
        CARREFOUR_HOME_DLVRY: "/other-deliveries/home-delivery",
        Hubs: "/other-deliveries/hubs",
        Tracking: "/other-deliveries/tracking",
      },
      CHD_GROUP_ID: "other-deliveries",
      CHD_GROUP: [
        {
          route: "/other-deliveries/home-delivery",
          isListItem: true,
        },
        {
          route: "/other-deliveries/hubs",
          isListItem: true,
        },
        {
          route: "/other-deliveries/tracking",
          isListItem: true,
        },
      ],
    },
    DELVE_GROUP_ID: "delve",
    DELVE_GROUP: [
      {
        route: "/delve/dashboard",
        isListItem: true,
      },
      {
        route: "/delve/order-list",
        isListItem: true,
      },
    ],
    /////For Testing Purpose
    STD_DELIVERIES_GROUP_ID: "standard",
    STD_DELIVERIES_GROUP: [
      {
        route: "/standard/planning",
        isListItem: true,
      },
      {
        route: "/standard/performance-dashboard",
        isListItem: true,
      },
      {
        route: "/standard/hub-wise",
        isListItem: true,
      },
      {
        route: "/standard/tracking",
        isListItem: true,
      },
      {
        route: "/standard/hubs",
        isListItem: true,
      },
      {
        route: "/standard/cod-reconciliation",
        isListItem: true,
      },
      {
        route: "/standard/express-leb",
        isListItem: true,
      },
    ],
    EXP_DELIVERIES_GROUP_ID: "express-delivery",
    EXP_DELIVERIES_GROUP: [
      {
        route: "/express-delivery/express",
        isListItem: true,
      },
      {
        route: "/express-delivery/drivers",
        isListItem: true,
      },
      {
        route: "/express-delivery/pos",
        isListItem: true,
      },
      {
        route: "/express-delivery/cod-reconciliation",
        isListItem: true,
      },
    ],
    OFFLINE_DELIVERIES_GROUP_ID: "other-deliveries",
    OFFLINE_DELIVERIES_GROUP: [
      {
        route: "/other-deliveries/click-and-collect",
        isListItem: true,
      },
      {
        route: "/other-deliveries/valet-trolley",
        isListItem: true,
      },
      {
        route: "/other-deliveries/home-delivery",
        isListItem: true,
      },
    ],
  },
};

export default RouteConstants;
