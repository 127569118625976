import React, { useCallback, forwardRef, ReactElement, Ref, useState, useEffect } from 'react';
import CustomDialog from '../common/Dialog';
import { useDispatch, useSelector } from "react-redux";
import { fetchConsignmentHistoryAndProducts, exportInvoicePdf, getCratesInfo, closeDialog, resetReplanningSuccess, fetchExpressSlotDropdownValues, getAddressStatus, fetchStatusOnClick, resetStatusRefreshButton, fetchOrderLogs, fetchDeliveryReasons, fetchDeliveryReasonsCountryBased, } from "./redux/planningSlice";
import mobileLogoIcon from './../assets/icons/mobileLogo.svg';
import emailLogoIcon from './../assets/icons/emailLogo.svg';
import RcViewer from '@hanyk/rc-viewer';
import { AppState } from "../config/redux/reducers";
import { ReactComponent as editIcon } from './../assets/icons/edit-icon.svg';
import { ReactComponent as editIconNew } from './../assets/icons/edit-icon-new.svg';
import { ReactComponent as refreshIconNew } from './../assets/icons/refresh-icon-new.svg';
import { ReactComponent as refreshIconGrey } from './../assets/icons/refresh-icon-grey.svg';
import { ReactComponent as activeTimelineDot } from './../assets/icons/active-timeline-dot.svg';
import { ReactComponent as errorTimelineDot } from './../assets/icons/timeline-dot-error.svg';
import { ReactComponent as crateStageLocationIcon } from './../assets/icons/crate-staging-location-icon.svg';
import { ReactComponent as timelineDot } from './../assets/icons/timeline-dot.svg';
import { ReactComponent as downArrowIcon } from './../assets/icons/down-arrow.svg';
import {ReactComponent as ShieldCheck} from '../assets/icons/ShieldCheck.svg';

import {getDateOrTimeInReqFormat} from '../utils/dateUtils';
import { ReactComponent as zoomInIcon } from './../assets/icons/zoom-in.svg';
import { Countdown } from '../utils/countdownTimer';
import { ReactComponent as zoomOutIcon } from './../assets/icons/zoom-out.svg';
import locationIcon from './../assets/icons/map-pin.svg';
import verifiedIcon from './../assets/icons/verified-icon.svg';
import unverifiedIcon from './../assets/icons/unverified-icon.svg';    
import pendingIcon from './../assets/icons/pending-icon.svg';
import { useStyles } from './ConsignmentDetails.styles';
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, SvgIcon, Collapse, Link, Divider, FormControlLabel, Checkbox, Tabs, Tab } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@material-ui/lab';
import { COLORS } from '../config/theme/baseTheme';
import MapContainer from '../common/GoogleMapContainer';
import { TabContext, TabPanel } from '@material-ui/lab';
import Loader from "../common/Loader";
import AppConstants from "../constants";
import {TIMER_CONST} from "../constants/CommonConstants"
import { getAddressInfo, getCratesFromProducts, createCratesInfoPayload, createPayloadToMarkAddressUnverified, getDeliveryExecutionStatusField} from '../mocks/routePlanning/response.transforms';
import CustomBarcode from 'common/CustomBarcode';
import { fixDecimals, localTimeString, isDisplayStatusEditable, displayUnitGrams, isCardOnDelivery } from 'utils/helpers.utils';
import moment from 'moment';
import ConsignmentEdit from './ConsignmentEdit';
import { Document, Page } from 'react-pdf';
import Modal from '@material-ui/core/Modal';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import clsx from "clsx";
import { pdfjs } from 'react-pdf';
import closeButton from "./../assets/icons/close-circle-icon.svg";
import { Button } from "@material-ui/core";
import { isExisty, getObjectKeys, isArrayNonEmpty, isModifiedBySystem } from "../utils/helpers.utils";
import { ADDRESS_STATUS } from '../constants/CommonConstants';
import BundleProducts from '../BundleProducts/index'

import { MARKER_URL, mapOptionStyles } from './AllRoutes/utils/AllRouteConstant';
import RenderRescheduleOrders from '../common/RescheduleOrders/RenderRescheduleOrders';
import { decodeHtmlEntities } from 'utils/textUtils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface ConsignmentDetailsProps {
  open: boolean;
  consignment: any;
  isExpress?: any;
  isFromViewRoute?: any;
  isEditIcon?: any
  closePopup: () => void;
  handleTimerInstances?:(id:any) => void;
  timerStarted?: boolean
  setShowTime?: (showTime: boolean) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConsignmentDetails = (props: ConsignmentDetailsProps) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, invoiceData, invoiceUrl,  displayInvoice, invoiceMessage, invoiceConsignment, consignmentStatuses, productsList, bundleProductList, cratesFromAlecto, orderLevelCrateInfo, productsListFromAlecto, consignmentDataAfterReplanning, productsAfterUpdateLineItem, revisedAmountAfterCalculation, consignmentDataAfterAddressUpdate, isPolled, polledConsignmentOmsData, orderLogs, reasonsDropdownList, crateInfo, bundleProductAfterUpdateLineItem } = useSelector(
    (state: AppState) => state.routePlanning
  );
  const { isAdmin, isLastMileViewer, isOnlyStdLastMileViewer } = useSelector(
    (state: AppState) => state.userLogin
  );
  const { countryCode } = useSelector(
    (state: AppState) => state.common
  );
  const { open, consignment, isExpress, isFromViewRoute, isEditIcon, closePopup, handleTimerInstances, timerStarted, setShowTime } = props;  
  const [remainingTime, setRemainingTime] = useState(TIMER_CONST.pullTimeForRefresh);
  const [timersValue, setTimersValue] = useState({});
  const [toggleCustMap, setToggleCustMap] = useState(false);
  const [toggleSellerMap, setToggleSellerMap] = useState(false);
  const [openConsignmentEditPopup, setOpenConsignmentEditPopup] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [showOriginalName, setShowOriginalName] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [scale, setScale] = useState(1);
  const [scaleNumber, setScaleNumber] = useState(100);
  const [isCrateInfo, setIsCrateInfo] = useState(false);
  const [isCratesAvailable, setIsCratesAvailable] = useState(false);
  const [addressVerified, setAddressVerified] = useState(false);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [dataAfterOmsPull, setDataAfterOmsPull] = useState({} as any);
  const [tabValue, setTabValue] = useState(AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_DETAIL.ORDER_DETAIL);

  let deliveryInformation: any = {};
  let metadata: any = {};
  let custInfo: any = { address: {} };
  let custGeoLocation: any = { name: "", location: { lat: 0, lng: 0 }, markers: [] };
  let sellerGeoLocation: any = { name: "", location: { lat: 0, lng: 0 }, markers: [] };
  let posInfo: any = {
    address: {}
  };
  let items: any = [];
  let bundleProduct: any = [];
  let historyItems: any = [];
  let crates: any = [];
  let totalNumberOfItems: Number = 0;
  let cratesDetailsFromAlecto: any = cratesFromAlecto.crateAvailableOrders;

  let deliveryDate = "";
  let deliverySlot = "";
  let hubName = "";
  let shippingStatus = "";
  let addressStatusAfterUpdate = ""
  let isCheckboxDisabled = false;
  let deliveryInformationAfterAddressUpdate: any = {};
  let custInfoAfterAddressUpdate: any = {
    address: {}
  }

  const handleStatusHistoryAndProducts = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchConsignmentHistoryAndProducts({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  const handleDeliverySlotsForExpress = useCallback(
    (countryCode: any) => {
      dispatch(
        fetchExpressSlotDropdownValues({
          countryCode: countryCode
        })
      );
    },
    [dispatch]
  );

  if (consignment) {
    deliveryInformation = consignment.deliveryInformation;
    metadata = consignment.metadata;
    shippingStatus = metadata && metadata.shippingStatus ? metadata.shippingStatus : "";
    custInfo = deliveryInformation && deliveryInformation.customerContactInfo;
    // statusErrorReason = consignment.statuses && consignment.statuses.length && consignment.statuses[0].reason ? consignment.statuses[0].reason : consignment.reason ? consignment.reason : {};
    if (custInfo && custInfo.address) {
      custGeoLocation.name = custInfo.name;
      const latLng = { lat: parseFloat(custInfo.address.latitude) || 0, lng: parseFloat(custInfo.address.longitude) || 0 };
      custGeoLocation.location = latLng;
      const marker = { name: custInfo.name, location: latLng, icon: { url: MARKER_URL.RED_DOT_MARKER } };
      custGeoLocation.markers.push(marker);
    }
    if (consignment.posInformation) {
      posInfo = consignment.posInformation.contactInfo;
      hubName = consignment.posInformation.storeName || consignment.posInformation.store;
    }
    if (deliveryInformation && deliveryInformation.deliverySlot) {
      const startTime = new Date(deliveryInformation.deliverySlot.startTime);
      const endTime = new Date(deliveryInformation.deliverySlot.endTime);
      deliveryDate = moment(deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND).format(AppConstants.DATE_FORMAT);
      deliverySlot = `${localTimeString(startTime)} - ${localTimeString(endTime)}`
    }
    if (posInfo && posInfo.address) {
      sellerGeoLocation.name = hubName;
      const latLng = { lat: parseFloat(posInfo.address.latitude) || 0, lng: parseFloat(posInfo.address.longitude) || 0 };
      sellerGeoLocation.location = latLng;
      const marker = { name: hubName, location: latLng, icon: { url: MARKER_URL.RED_DOT_MARKER } };
      sellerGeoLocation.markers.push(marker);
    }
   
    items = productsList;
    bundleProduct= bundleProductList
    if (productsAfterUpdateLineItem && productsAfterUpdateLineItem.length > 0) {
      items = productsAfterUpdateLineItem;
    }
    if(bundleProductAfterUpdateLineItem?.length > 0 ){
         bundleProduct = bundleProductAfterUpdateLineItem
    }
    totalNumberOfItems = items?.length + bundleProduct?.length

    crates =  orderLevelCrateInfo.length ? orderLevelCrateInfo : getCratesFromProducts(productsList);
    cratesDetailsFromAlecto = getCratesFromProducts(productsListFromAlecto);
    if (consignmentStatuses) {
      let statuses = consignmentStatuses.slice();
      historyItems = statuses;
    }
    if (consignment?.deliveryInformation?.customerContactInfo?.address?.latitude) {
      const latLng = {
        lat: parseFloat(consignment?.deliveryInformation?.customerContactInfo?.address?.latitude) || 0,
        lng: parseFloat(consignment?.deliveryInformation?.customerContactInfo?.address?.longitude) || 0,
      };
      const marker = {
        name: consignment?.deliveryInformation?.customerContactInfo?.name,
        location: latLng,
        icon: { url: MARKER_URL.GREEN_DOT_MARKER },
      };
      sellerGeoLocation.markers.push(marker);
    }
    if (consignment?.deliveryInformation?.deliveryProof?.executedLocation?.latitude) {
      const latLng = {
        lat: parseFloat(consignment?.deliveryInformation?.deliveryProof?.executedLocation?.latitude) || 0,
        lng: parseFloat(consignment?.deliveryInformation?.deliveryProof?.executedLocation?.longitude) || 0,
      };
      const marker = {
        name: consignment?.deliveryInformation?.driver?.name || AppConstants.DRIVER,
        location: latLng,
        icon: { url: MARKER_URL.GREEN_DOT_MARKER },
      };
      custGeoLocation.markers.push(marker);
    }
  }
  if (consignmentDataAfterAddressUpdate) {
    deliveryInformationAfterAddressUpdate = consignmentDataAfterAddressUpdate.deliveryInformation;
    custInfoAfterAddressUpdate = deliveryInformationAfterAddressUpdate && deliveryInformationAfterAddressUpdate.customerContactInfo;
    addressStatusAfterUpdate = custInfoAfterAddressUpdate && custInfoAfterAddressUpdate.address && custInfoAfterAddressUpdate.address.status;
    if (addressStatusAfterUpdate === ADDRESS_STATUS.UNVERIFIED) {
      isCheckboxDisabled = true;
    }
  }

  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot
    },
    square: true
  }

  const confirmDialogPaperProps = {
    classes: {
      root: classes.confirmDialogPaperPropsRoot
    }
  };

  const handleTrackingLink = () => {
    const trackingUrl = `${deliveryInformation.carrierTrackingUrl}&source=portal`
    window.open(trackingUrl, '_blank');
  }

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }

  const handleOriginalName = () => {
    if (!showOriginalName) {
      setShowOriginalName(true);
    } else {
      setShowOriginalName(false);
    }
  }

  const getStatusField = (fieldName: string, statusCode: string) => {
    const statuses: any = AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
    return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
  }

  const checkEditable = () => {
    if (Object.keys(consignmentDataAfterReplanning).length !== 0) {
      return AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus) === -1;
    } else {
      return AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.NON_EDITABLE_STATUSES.indexOf(metadata && metadata.deliveryStatus) === -1;
    }
  }

  const getBundleProduct =() =>{
   return  bundleProduct.map((item: any, index: number) => (
       <BundleProducts bundle={item} key={index} bundleCurrency={metadata?.currency}/>
   ))
  }

  const getItems = () => {
    return items && items.length && items.map((item: any, index: number) => (
      <Grid key={`${item.name.en} - ${index}`} className="item" item>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn item1" item xs={4}>
            <Typography>{
            item.substitutedProducts 
            && item.substitutedProducts.length 
            && item.substitutedProducts[0].name 
            && item.substitutedProducts[0].name.en 
              ? decodeHtmlEntities(item.substitutedProducts[0].name.en.toLowerCase())
              : item.name && item.name.en 
                ? decodeHtmlEntities(item.name.en.toLowerCase()) : ""
                }</Typography>
            {
              isExisty(item.reason) && isExisty(item.reason.reasonCode) && isExisty(item.reason.reasonCode) ? (
                <Typography className="rejectedReason">{`Rejection Reason - ${item.reason.reasonDescription}`}</Typography>) :
                isExisty(item.substitutedProducts) && item.substitutedProducts.length && isExisty(item.substitutedProducts[0].reason) && isExisty(item.substitutedProducts[0].reason.reasonCode) ? (
                  <Typography className="rejectedReason">{`Rejection Reason - ${item.substitutedProducts && item.substitutedProducts.length && item.substitutedProducts[0].reason && item.substitutedProducts[0].reason.reasonDescription}`}</Typography>
                ) : (
                    <Typography>{""}</Typography>
                  )
            }
            {
              item.substitutedProducts &&
              <div style={{ display: "flex" }}>
                <Typography className="viewOriginalLink"> View Original</Typography>
                <KeyboardArrowDownRoundedIcon className={clsx(!showOriginalName && classes.closeY, showOriginalName && classes.openY)} onClick={() => handleOriginalName()} />
              </div>
            }
          </Grid>
          <Grid className="itemColumn item2" item xs={2}>
              <Typography>
                <span>
                  {item.substitutedProducts &&
                  item.substitutedProducts.length &&
                  (item.substitutedProducts[0]?.rejectedQty !== undefined && item.substitutedProducts[0]?.rejectedQty !== '0')
                    ?  `${item.substitutedProducts[0]?.rejectedQty } ${
                        item.substitutedProducts[0].uom ?
                        item.substitutedProducts[0].uom === "Grams"
                          ? displayUnitGrams(item.substitutedProducts[0].uom)
                          : ""
                          : item.uom && item.uom === "Grams"? displayUnitGrams(item.uom): ""
                       }`
                    : item?.rejectedQty && Number(item?.rejectedQty)
                    ? `${item?.rejectedQty || '-'} ${
                      item.uom && item.uom === "Grams"
                        ? displayUnitGrams(item.uom)
                        : ""
                    }`
                    : "-"}
                </span>
              </Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography>
                <span className="green">
                  {item.substitutedProducts && item.substitutedProducts.length
                    ? `${item.substitutedProducts[0].shippedQty} ${
                        item.substitutedProducts[0].uom ?
                        item.substitutedProducts[0].uom === "Grams"
                          ? displayUnitGrams(item.substitutedProducts[0].uom)
                          : ""
                          : item.uom && item.uom === "Grams"? displayUnitGrams(item.uom): ""
                      }`
                    : `${item.shippedQty || 0} ${
                        item.uom && item.uom === "Grams"
                          ? displayUnitGrams(item.uom)
                          : ""
                      }`}{" "}
                </span>
              </Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography>
                <span>{`${item.quantity || ""} ${
                  item.uom && item.uom === "Grams"
                    ? displayUnitGrams(item.uom)
                    : ""
                }`}</span>
              </Typography>
            </Grid>
            <Grid className="itemColumn item3" item xs={2}>
              <Typography>{`${fixDecimals(
                2,
                item.substitutedProducts &&
                  item.substitutedProducts.length &&
                  item.substitutedProducts[0].unitPrice
                  ? item.substitutedProducts[0]?.unitPrice
                  : item.unitPrice
                  ? item.unitPrice.toString()
                  : ""
              )} ${metadata && metadata.currency}`}</Typography>
            </Grid>
        </Grid>
        {
          showOriginalName && item.substitutedProducts &&
          <Grid className="itemContainer items" container>
            <Grid className="itemColumn item1" item xs={4}>
              <Typography className="originalName">{item.name.en.toLowerCase()}</Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
              <Typography><span>{item.rejectedQty || "-"}</span></Typography>

            </Grid>

            <Grid className="itemColumn item2" item xs={2}>
              <Typography><span className="green">{'-'}</span></Typography>
            </Grid>
            <Grid className="itemColumn item2" item xs={2}>
                <Typography>
                  <span >{`${item.quantity || ""} ${
                    item.uom && item.uom === "Grams"
                      ? displayUnitGrams(item.uom)
                      : ""
                  }`}</span>
                </Typography>
              </Grid>
            <Grid className="itemColumn item3" item xs={2}>
              <Typography>{`${fixDecimals(2, item.unitPrice ? item.unitPrice.toString() : "")} ${metadata && metadata.currency}`}</Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    ))
  }

  const getCrateItems = () => {
    return crateInfo && crateInfo.length ? crateInfo.map((crate: any, index: number) => (
      <Grid key={crate.crateId} className="item" item style={{ paddingTop: "16px" }}>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn item1" item xs={4}>
            <Grid container alignItems="center">
              <Typography>{crate.crateId}</Typography>
              <CustomBarcode value={crate.crateId} options={{ displayValue: false }} imgProps={{ alt: "Crate Barcode", className: "crateBarcode" }} />
            </Grid>
          </Grid>
          <Grid className="itemColumn item2" item xs={4}>
            <Typography>{crate.productsCountPerCrate !== 0 &&  crate.productsCountPerCrate}</Typography>
          </Grid>
          <Grid className="itemColumn item3" item xs={4}>
            <Grid container alignItems="center">
              <SvgIcon className="crateStageLocationIcon" component={crateStageLocationIcon} viewBox="0 0 16 16" />
              <Typography>{crate.locationCode}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )) : (<Typography>No Items to display</Typography>)
  }

  const getCrateItemsFromAlecto = () => {
    return cratesDetailsFromAlecto && cratesDetailsFromAlecto.length ? cratesDetailsFromAlecto.map((crate: any, index: number) => (
      <Grid key={crate.crateId} className="item" item style={{ paddingTop: "16px" }}>
        <Grid className="itemContainer" container>
          <Grid className="itemColumn item1" item xs={4}>
            <Grid container alignItems="center">
              <Typography>{crate.crateId}</Typography>
              <CustomBarcode value={crate.crateId} options={{ displayValue: false }} imgProps={{ alt: "Crate Barcode", className: "crateBarcode" }} />
            </Grid>
          </Grid>
          <Grid className="itemColumn item2" item xs={4}>
            <Typography>{crate.quantity}</Typography>
          </Grid>
          <Grid className="itemColumn item3" item xs={4}>
            <Grid container alignItems="center">
              <SvgIcon className="crateStageLocationIcon" component={crateStageLocationIcon} viewBox="0 0 16 16" />
              <Typography>{crate.locationCode}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )) : (<Typography style={{ fontSize: "14px", paddingTop: "24px" }}>Crate information updates susccessfuly</Typography>)
  }
  const handleDialogClose = useCallback(
    () => {
      closePopup();
      dispatch(
        closeDialog()
      );
      setTabValue(AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_DETAIL.ORDER_DETAIL);
    },
    [closePopup, dispatch],
  );

  const getCustomerImages = useCallback(
    () => {
      return deliveryInformation && deliveryInformation.deliveryProof && deliveryInformation.deliveryProof.imageURLs ? deliveryInformation.deliveryProof.imageURLs.map((image: any, index: number) => (
        <img key={`customer-image-${index}`} className="customer-proof-img" alt="customer-delivery-proofs" src={image} />
      )) : null;
    },
    [deliveryInformation],
  );

  /* const checkStatusReason = useCallback(
    (reason: any, statusCode: string) => {
      return reason && reason.deliveryStatus ? reason.deliveryStatus === statusCode ? true : false : false;
    },
    [],
  ); */

  const closeConsignmentEditPopup = useCallback(
    () => {
      setOpenConsignmentEditPopup(false);
    },
    [],
  );

  const fetchAllDeliveryReasons = () => {
    dispatch(fetchDeliveryReasonsCountryBased());
  }

  useEffect(() => {
    if(openConsignmentEditPopup && !(reasonsDropdownList.length)){
      fetchAllDeliveryReasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, openConsignmentEditPopup])

  const handleEditPopup = useCallback(
    () => {
      dispatch(
        resetReplanningSuccess()
      )
      setOpenConsignmentEditPopup(true);
    },
    [dispatch],
  );

  const handleInvoiceModalClose = () => {
    setShowInvoiceModal(false);
    setScale(1);
  };

  const zoomIn = (scaleNumber: any) => {
    setScaleNumber(scaleNumber + 15);
    setScale(scale + 0.4);
  };

  const zoomOut = (scaleNumber: any) => {
    if (scale > 1) {
      setScaleNumber(scaleNumber - 15);
      setScale(scale - 0.4);
    } else {
      setScale(1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressVerified(event.target.checked);
    setOpenAddressPopup(true)
  };

  const handleAddressDialogClose = useCallback(
    () => {
      setOpenAddressPopup(false);
    },
    [],
  );

  const handleMarkAddressVerified = useCallback(
    (consignment: any, address: boolean) => {
      const payload = createPayloadToMarkAddressUnverified(consignment, address);
      dispatch(
        getAddressStatus({
          params: payload
        })
      );
      setOpenAddressPopup(false);
    },
    [dispatch]
  )

  const updateAddressDialogActionHandler = useCallback(
    (type: string, order?: string, address?: any) => {
      if (type === 'yes') {
        setAddressVerified(true);
        handleMarkAddressVerified(order, true);
      } else {
        setOpenAddressPopup(false);
        setAddressVerified(false);
      }
    },
    [handleMarkAddressVerified],
  )

  const handleInvoicePdf = useCallback(
    (consignment) => {
      const paramsInvoice = {
        consignmentNo: [consignment.consignmentCode]
      };
      setShowInvoiceModal(true);
      dispatch(
        exportInvoicePdf({
          params: paramsInvoice
        })
      )
    },
    [dispatch]
  );

  const handleCratesInfoFromAlecto = useCallback(
    (consignment: any) => {
      setIsCrateInfo(true);
      setIsCratesAvailable(true);
      const payload = createCratesInfoPayload(consignment);
      dispatch(
        getCratesInfo({
          params: payload
        })
      )
    },
    [dispatch]
  );

  const handleCrateSummary = () => {
    setIsCratesAvailable(false);
  }

  const addressStatus = (status: any) => {
    if (status === ADDRESS_STATUS.VERIFIED) {
      return <img src={verifiedIcon} alt="verified-icon" />
    } else {
      if (status === ADDRESS_STATUS.UNVERIFIED) {
        return <img src={unverifiedIcon} alt="unverified-icon" />
      } else {
        return <img src={pendingIcon} alt="pending-icon" />
      }
    }
  }

  const getAddressDialogTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
      </Grid >
    )
  }

  const getAddressDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography style={{ fontSize: "14px", fontWeight: 500, color: "#252525" }}>
            Do you want to mark this address unverified?</Typography>
        </Grid>
      </Grid>
    )
  };
  const getAddressDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={() => { updateAddressDialogActionHandler('no') }}>{AppConstants.BUTTONS.NO}</Button>
        <Button className="dialogBtn primary" variant="contained" onClick={() => { updateAddressDialogActionHandler('yes', consignment, addressVerified) }}>{AppConstants.BUTTONS.YES}</Button>
      </Grid>
    )
  }

  const renderInvoiceModal = () => {
    return (
      <Modal open={showInvoiceModal} onClose={handleInvoiceModalClose}>
        <>
          {loading && <Loader></Loader>}
          {!loading && displayInvoice &&
            <>
              <Grid container className={classes.pdfHeader}>
                <Grid item xs={3} className={classes.pdfRoot}>
                  <Typography className={classes.pdfName}>{invoiceConsignment}.pdf ({numPages} Pages)</Typography>
                </Grid>
                <Grid item xs={6} className={classes.pdfRoot}>
                  <SvgIcon component={zoomOutIcon} className={classes.zoomOutStyle} onClick={() => zoomOut(scaleNumber)} />
                  <Typography className={classes.pdfScaleNo}>{scaleNumber}</Typography>
                  <SvgIcon component={zoomInIcon} onClick={() => zoomIn(scaleNumber)} className={classes.zoomInStyle} /></Grid>
                <Grid item xs={3} className={classes.closeModalIcon}>
                  <img src={closeButton} alt="Modal close button" onClick={handleInvoiceModalClose} />
                </Grid>

              </Grid>
              <div className={classes.invoiceModalBody}>
                <div style={{ overflow: "scroll", maxHeight: "920px", height: "100%" }}>
                  <Document
                    file={invoiceUrl ? {url:invoiceUrl} : invoiceData}
                    onLoadSuccess={onDocumentLoadSuccess}
                    noData="Please select a file"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
                    ))}
                  </Document>
                </div>
              </div></>
          }
          {!loading && !displayInvoice &&
            <div className={classes.noPdfModal}>
              <div style={{ overflow: "scroll", maxHeight: "890px", height: "100%" }}>
                <div className={classes.closeNoPdfModalIcon} onClick={() => setShowInvoiceModal(false)}>
                  <img src={closeButton} alt="Modal close button" />
                </div>
                <p className={classes.noInvoiceMsg}>{invoiceMessage}</p>
              </div>
            </div>
          }
        </>
      </Modal >
    );
  };

  const getHistoryTimelineItems = () => {
    return historyItems && historyItems.length ? historyItems.map((item: any, index: number) => (
      < TimelineItem key={`${item.statusCode} - ${index}`} className="timelineItem" >
        <TimelineSeparator>
          {index === 0 ?
            <TimelineDot className="timelineDot">
              <SvgIcon className="dotIcon active" component={item.statusCode === "DELIVERY_FAILED" ? errorTimelineDot : activeTimelineDot} viewBox="0 0 18 18" />
            </TimelineDot>
            :
            <TimelineDot className="timelineDot">
              <SvgIcon className="dotIcon" component={timelineDot} viewBox="0 0 8 8" />
            </TimelineDot>
          }
          {index !== historyItems.length - 1 && <TimelineConnector className="primaryConnector" />}
        </TimelineSeparator>
        <TimelineContent className="timelineContent">
          <Typography className={["heading", index === 0 ? "active" : "", (item.statusCode === "DELIVERY_FAILED") ? "error" : ""].join(" ")}><span className="status">{item.statusName}</span><span className="dateTime">{`(${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
          })})`}</span></Typography>
          {
            item.reason ? (
              <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                <Typography className="title">{item.reason ? item.reason.reasonDescription : ""}</Typography>
                <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                  hour12: false,
                  hour: 'numeric',
                  minute: 'numeric',
                })})`}</Typography>
              </Typography>) :
              consignment.reason && consignment.reason.deliveryStatus === item.statusCode ? (
                <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
                  <Typography className="title">{consignment.reason ? consignment.reason.reasonDescription : ""}</Typography>
                  <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                  })})`}</Typography>
                </Typography>
              ) : ""
          }
          {/* {
            checkStatusReason(statusErrorReason, item.statusCode) && <Typography key={`errorMetadata-${index}`} component="div" className="metadataContainer">
              <Typography className="title">{statusErrorReason ? statusErrorReason.reasonDescription : ""}</Typography>
              <Typography className="description">{`By System on (${new Date(item.statusDate).toLocaleDateString()} at ${new Date(item.statusDate).toLocaleTimeString('en-US', {
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
              })})`}</Typography>
            </Typography>
          } */}
        </TimelineContent>
      </TimelineItem >
    )) : (<Typography>No Items to display</Typography>)
  }

    const fetchStatus = useCallback(
      (consignment: any) => {
        if (Object.keys(consignment).length) {
          let consignmentCode = consignment && consignment.consignmentCode;
          dispatch(
            fetchStatusOnClick({
              params: consignmentCode,
            })
          );
        }
      },
      [dispatch]
    );

 const checkRefreshableStatus = () => {
      let status = metadata && metadata.deliveryStatus;
      return (
        AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.REFRESHABLE_STATUSES.indexOf(
          status
        ) !== -1
      );
    };
 const handleClick = () => {
   fetchStatus(consignment);
   setRemainingTime(TIMER_CONST.pullTimeForRefresh);
   handleTimerInstances && handleTimerInstances(consignment.consignmentCode);
 };

 useEffect(() => {
   let timer: any = {};
   if (timerStarted) {
     timer = setInterval(() => {
       setRemainingTime((prevTime) => prevTime - 1);
       const countdownInstance = Countdown.getInstance();
       setTimersValue(countdownInstance);
     }, 1000); // update every second
   }

   return () => {
     clearTimeout(timer);
   };
 }, [timerStarted === true]);

 useEffect(() => {
   if (remainingTime === Number(0)) {
     dispatch(resetStatusRefreshButton());
     setShowTime && setShowTime(false);
   }
 }, [remainingTime, dispatch]);

  useEffect(() => {
     setDataAfterOmsPull(polledConsignmentOmsData)
  }, [polledConsignmentOmsData]);



  const getTitleContent = () => {
    return (
      <Grid className={classes.titleContainer} container>
        <Grid item>
          <Grid className="headingContainer" container>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className="titleHeading">
                    <span className="label"> CONSIGNMENT ID : </span>
                    <span className="content"> {consignment.consignmentCode} </span>      
                  </Typography>
                </Grid>
                {consignment && consignment?.secureInfo && consignment?.secureInfo?.isVerified && (
                    <Grid item>
                      <SvgIcon component={ShieldCheck} className={classes.topLevelShieldCheckStyle} />
                    </Grid>
                )} 
                {consignment?.deliveryInformation?.deliverySlot?.rescheduled && (
                  <Grid item>
                    <RenderRescheduleOrders />  
                  </Grid>
                )}
                {consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata ?
                    (
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus)].join(" ")}>{consignmentDataAfterReplanning && consignmentDataAfterReplanning.metadata && consignmentDataAfterReplanning.metadata.deliveryStatus ? consignmentDataAfterReplanning.metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    ) : dataAfterOmsPull?.metadata ?  (
                       <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', dataAfterOmsPull?.metadata?.deliveryStatus)].join(" ")}>{dataAfterOmsPull?.metadata?.deliveryStatus}</Typography>
                      </Grid>
                    )  : (
                      metadata && metadata.deliveryStatus &&
                      <Grid className="tagContainer" item>
                        <Typography className={['tag', getStatusField('color', metadata && metadata.deliveryStatus)].join(" ")}>{metadata && metadata.deliveryStatus ? metadata.deliveryStatus : ""}</Typography>
                      </Grid>
                    )
                }
                {!(isLastMileViewer || isOnlyStdLastMileViewer) &&
                <>
                {
                  isFromViewRoute ?
                    (
                      checkEditable() && !isEditIcon &&
                      <Grid className="editIcon" item>
                        <SvgIcon className="icon" onClick={handleEditPopup} component={editIconNew} />
                      </Grid>
                    ) : (
                      checkEditable() && isDisplayStatusEditable(metadata, countryCode) &&
                      <Grid className="editIcon" item>
                        <SvgIcon className="icon" onClick={handleEditPopup} component={editIconNew} />
                      </Grid>
                    )
                }
                {checkRefreshableStatus() && (
                  <Grid className="editIcon" item>
                    {!timersValue[
                      consignment.consignmentCode as keyof typeof timersValue
                    ] && (
                      <SvgIcon
                        className="icon"
                        onClick={handleClick}
                        component={refreshIconNew}
                      />
                    )}
                    {timerStarted &&
                      timersValue[
                        consignment.consignmentCode as keyof typeof timersValue
                      ] && (
                        <>
                          <SvgIcon
                            className="icon refresh-block"
                            component={refreshIconGrey}
                          />
                          <span className="refreshText">
                            {" "}
                            Refresh in{" "}
                            {
                              timersValue[
                                consignment.consignmentCode as keyof typeof timersValue
                              ]
                            }{" "}
                            secounds{" "}
                          </span>
                        </>
                      )}
                  </Grid>
                )}
                </>
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <div style={{ display: "flex" }}>
                  {(deliveryInformation && deliveryInformation.carrierTrackingId) || consignment.orderReference 
                    ? (
                        deliveryInformation && deliveryInformation.carrierTrackingUrl 
                          ? (<>
                              <Link className="titleContent link" onClick={handleTrackingLink}>{`Live Tracking (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Link>
                              <div className={classes.verticalDiv} />
                              <Link className="titleContent link" onClick={() => handleInvoicePdf(consignment)}> View Invoice</Link>
                              {deliveryInformation?.deliveryExecutionStatus && (
                                <>
                                  <div className={classes.verticalDiv} />
                                  <Typography className={["titleContent", getDeliveryExecutionStatusField("color", deliveryInformation.deliveryExecutionStatus)].join(" ")}>{`${deliveryInformation?.deliveryExecutionStatus || ""}`}</Typography>
                                </>
                              )}
                            </>)
                          : <>
                            <Typography className="titleContent">{`Track Consignment (#${deliveryInformation && deliveryInformation.carrierTrackingId ? deliveryInformation.carrierTrackingId : consignment.orderReference ? consignment.orderReference : ""})`}</Typography>
                            {deliveryInformation?.deliveryExecutionStatus && (
                              <>
                                <div className={classes.verticalDiv} />
                                <Typography className={["titleContent", getDeliveryExecutionStatusField("color", deliveryInformation.deliveryExecutionStatus)].join(" ")}>{`${deliveryInformation?.deliveryExecutionStatus || ""}`}</Typography>
                              </>
                            )}
                          </>
                      ) 
                    : deliveryInformation?.deliveryExecutionStatus && <Typography className={["titleContent", getDeliveryExecutionStatusField("color", deliveryInformation.deliveryExecutionStatus)].join(" ")}>{`${deliveryInformation?.deliveryExecutionStatus || ""}`}</Typography>
                  }
                </div>
              </Grid>
             </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      setTabValue(value);
    },
    []
  )

    const handleOrderLogs = useCallback(
    (consignment: any) => {
      if (Object.keys(consignment).length) {
        let consignmentCode = consignment && consignment.consignmentCode;
        dispatch(
          fetchOrderLogs({
            params: consignmentCode
          })
        );
      }
    },
    [dispatch]
  );

  const getOrderLogs = () => {
    return orderLogs && orderLogs.length ? (
      orderLogs.map((item: any, index: number) => (
        <TimelineItem key={`${item.key} - ${index}`} className='timelineItem'>
          <TimelineSeparator>
            {index === 0 ? (
              <TimelineDot className='timelineDot'>
                <SvgIcon
                  className='dotIcon active'
                  component={item.key === "DELIVERY_FAILED" ? errorTimelineDot : activeTimelineDot}
                  viewBox='0 0 18 18'
                />
              </TimelineDot>
            ) : (
              <TimelineDot className='timelineDot'>
                <SvgIcon className='dotIcon' component={timelineDot} viewBox='0 0 8 8' />
              </TimelineDot>
            )}
            {index !== orderLogs.length - 1 && <TimelineConnector className='primaryConnector' />}
          </TimelineSeparator>
          <TimelineContent className='timelineContent'>
            <Typography
              className={["heading", index === 0 ? "active" : "", item.key === "DELIVERY_FAILED" ? "error" : ""].join(
                " "
              )}
            >
              <span className='status'>{item.message}</span>
              {isModifiedBySystem(item.lastModifiedBy) && (
                  <span className='dateTime'>{` (${getDateOrTimeInReqFormat(
                      item.updatedAt,
                      AppConstants.DATE_FORMAT
                  )} at ${getDateOrTimeInReqFormat(item.updatedAt, AppConstants.SLOT_FORMAT_UI)})`}</span>
              )}
            </Typography>
            {!isModifiedBySystem(item.lastModifiedBy) && (
                <Typography className='lastModifiedContent'>
                  <span>Updated by</span>
                  <span className='name'>{item.lastModifiedBy}</span>
                  <span className='dateTime'>{` (${getDateOrTimeInReqFormat(
                      item.updatedAt,
                      AppConstants.DATE_FORMAT
                  )} at ${getDateOrTimeInReqFormat(item.updatedAt, AppConstants.SLOT_FORMAT_UI)})`}</span>
                </Typography>
            )}
            <Typography className='timelineContentItem'></Typography>
          </TimelineContent>
        </TimelineItem>
      ))
    ) : (
        <Typography>No Items to display</Typography>
    );
  };

  const getDetailsContent = () => {
    return (
        <Grid className={classes.contentContainer} container>
      <TabContext value={tabValue}>
         <Tabs value={tabValue} className={classes.tabs} aria-label='Order Navigation Tabs' onChange={handleTabChange}>
            <Tab
              className='tabLabel'
              label='Order Detail'
              value={AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_DETAIL.ORDER_DETAIL}
            />
            <Tab
              className='tabLabel'
              label='Order Log'
              value={AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_DETAIL.ORDER_LOG}
              onClick={() => handleOrderLogs(consignment)}
            />
          </Tabs>
          <TabPanel
            className='tabValues'
            value={AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_DETAIL.ORDER_DETAIL}
          >
        <Grid className='customerDetails' item>
          <Grid className='detailsHeader' container>
            <Grid className='detailsHeaderItem' item>
              <Typography>Customer Details</Typography>
            </Grid>
            <Grid className='detailsHeaderItem' item>
              <Grid container className='deliveryDetails'>
                <Grid item>
                  <Typography className='delivery'>{custInfo && custInfo.name}</Typography>
                </Grid>
                {addressStatusAfterUpdate ? (
                  <Grid item style={{marginLeft: "12px"}}>
                    {addressStatusAfterUpdate === ADDRESS_STATUS.UNVERIFIED ? (
                      <img src={unverifiedIcon} alt='info-icon' />
                    ) : (
                      ""
                    )}
                  </Grid>
                ) : (
                  <Grid item style={{marginLeft: "12px"}}>
                    {addressStatus(custInfo?.address?.status)}
                  </Grid>
                )}
              </Grid>
              <Grid item className='deliveryDetails' style={{display: "flex"}}>
                <img className='location' src={locationIcon} alt='location'></img>
                <Typography className='delivery'>
                  {getAddressInfo(custInfo && custInfo.address ? custInfo.address : "")}
                </Typography>
              </Grid>
            </Grid>
            <Grid className='detailsHeaderItem geocodedAddress' item>
              <Grid container direction='column'>
                <Grid className='addressColItem' item>
                  <Grid
                    container
                    className='geoLocAddressContainer'
                    alignItems='center'
                    onClick={() => setToggleCustMap(!toggleCustMap)}
                  >
                    <span className='address'>View Geocoded Address</span>
                    <SvgIcon
                      className={["addressLogo", toggleCustMap ? "open" : ""].join(" ")}
                      component={downArrowIcon}
                    />
                  </Grid>
                </Grid>
                <Collapse in={toggleCustMap}>
                  <Grid className='addressMapContainer' item>
                    {custGeoLocation ? (
                     <MapContainer
                     center={custGeoLocation.location}
                     zoom={17}
                     markers={custGeoLocation.markers}
                     showDirections={false}
                     roundTrip={false}
                     optimizeRoute={false}
                     updateMap={true}
                     showInfoWindow={true}
                     mapOptionStyles={mapOptionStyles}
                   />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <Grid className='detailsHeaderItem' item>
              <Grid container className='headerItemContainer'>
                <Grid className='contactMobile' item>
                  <Grid container className='contactItemContainer'>
                    <img className='logo' alt='Mobile' src={mobileLogoIcon} />
                    <span className='contact mobile'>
                      {custInfo ? <a href={`tel: ${custInfo.phone}`}>{custInfo.phone || ""}</a> : ""}
                    </span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container className='contactItemContainer'>
                    <img className='logo' alt='Email' src={emailLogoIcon} />
                    <span className='contact email'>
                      {custInfo ? <a href={`mailto: ${custInfo.email}`}>{custInfo.email || ""}</a> : ""}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!(isLastMileViewer || isOnlyStdLastMileViewer) && (
              <>
                {!(custInfo && custInfo.address && custInfo.address.status === ADDRESS_STATUS.UNVERIFIED) && (
                  <Grid container className='deliveryDetails'>
                    <FormControlLabel
                      classes={{root: classes.checkbox}}
                      label={<Typography className={classes.addressLabel}>Mark address as inaccurate</Typography>}
                      control={
                        <Checkbox
                          disabled={isCheckboxDisabled}
                          checked={addressVerified}
                          size='small'
                          color='primary'
                          onChange={handleChange}
                          inputProps={{"aria-label": "controlled"}}
                        />
                      }
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid className='detailsContent' container>
            <Grid className='contentRow' item>
              <Grid className='rowContainer' container>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Order No.</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{consignment.orderCode}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Delivery Date</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{deliveryDate}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Delivery Slot</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{deliverySlot}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className='contentRow' item>
              <Grid className='rowContainer' container>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Delivery Proposition</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{metadata ? metadata.deliveryType : ""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Delivery Area</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>
                        {custInfo && custInfo.address ? custInfo.address.area : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Payment Mode</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>{metadata ? metadata.paymentType : ""}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {metadata && metadata.routeName && (
              <Grid className='contentRow' item>
                <Grid className='rowContainer' container>
                  <Grid className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Route No.</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>{metadata.routeName}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Driver</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>
                          {deliveryInformation && deliveryInformation.driver && deliveryInformation.driver.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Vehicle</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>
                          {deliveryInformation && deliveryInformation.vehicle && deliveryInformation.vehicle.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid className='contentRow' item>
              <Grid className='rowContainer' container>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Payment Status</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>
                        {metadata && metadata.paymentStatus && metadata.paymentStatus === "PAYMENT_ACCEPTED"
                          ? "Payment Accepted"
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {deliveryInformation && deliveryInformation.helper && (
                  <Grid className='contentColumn' item>
                    <Grid container direction='column'>
                      <Grid item>
                        <Typography className='label'>Helper</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className='content'>
                          {deliveryInformation && deliveryInformation.helper && deliveryInformation.helper.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid className='contentColumn' item>
                  <Grid container direction='column'></Grid>
                </Grid>
              </Grid>
            </Grid>
            {deliveryInformation && deliveryInformation.deliveryInstructions && (
              <Grid className='contentRow' item>
                <Grid className='contentColumn' item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography className='label'>Delivery Instructions</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className='content'>
                        {deliveryInformation && deliveryInformation.deliveryInstructions}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
           {(metadata?.deliveryStatus === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.ORDER_STATUS.DELIVERY_FAILED ||
                  consignmentDataAfterReplanning?.metadata?.deliveryStatus ===
                  AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.ORDER_STATUS.DELIVERY_FAILED) && (
                  <Grid className="contentRow" item>
                    <Grid className="contentColumn" item>
                      <Grid container direction="column">
                        {consignmentDataAfterReplanning?.deliveryInformation
                          ?.reason ? (
                          <>
                            <Grid item>
                              <Typography className="label">
                                Undelivered Reason
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className="content">
                                {
                                  consignmentDataAfterReplanning
                                    ?.deliveryInformation?.reason
                                    ?.reasonDescription
                                }
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          deliveryInformation?.reason && (
                            <>
                              <Grid item>
                                <Typography className="label">
                                  Undelivered Reason
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography className="content">
                                  {
                                    deliveryInformation?.reason
                                      ?.reasonDescription
                                  }
                                </Typography>
                              </Grid>
                            </>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            <Grid className='contentRow hubDetails' item>
              <Grid className='contentColumn' item>
                <Grid container direction='column'>
                  <Grid item>
                    <Typography className='label'>Hub Details</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className='content'>{hubName}</Typography>
                    <Typography>{getAddressInfo(posInfo && posInfo.address ? posInfo.address : "")}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className='contentRow geocodedAddress' item>
              <Grid container direction='column'>
                <Grid className='addressColItem' item>
                  <Grid
                    container
                    className='geoLocAddressContainer'
                    alignItems='center'
                    onClick={() => setToggleSellerMap(!toggleSellerMap)}
                  >
                    <span className='address'>View Geocoded Address</span>
                    <SvgIcon
                      className={["addressLogo", toggleSellerMap ? "open" : ""].join(" ")}
                      component={downArrowIcon}
                    />
                  </Grid>
                </Grid>
                <Collapse in={toggleSellerMap}>
                  <Grid className='addressMapContainer' item>
                    {sellerGeoLocation ? (
                      <MapContainer
                        center={sellerGeoLocation.location}
                        zoom={10}
                        markers={sellerGeoLocation.markers}
                        showDirections={false}
                        roundTrip={false}
                        optimizeRoute={false}
                        updateMap={true}
                        showInfoWindow={true}
                        mapOptionStyles={mapOptionStyles}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className='deliveryProofSection' item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls='delivery-proof-content'
              id='delivery-proof-header'
              className={classes.accordionSummary}
            >
              <Typography className='header'>Proof of Delivery</Typography>
              {consignment && consignment?.secureInfo && consignment?.secureInfo?.isVerified && (
                <> 
                  <div className={classes.accordianVerticalDiv} />
                  <SvgIcon component={ShieldCheck} className={classes.shieldCheckStyle} />
                  <Typography className={classes.titleContent}>OTP Delivery</Typography>
                </>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='div' className='content'>
                <Grid className='deliveryProofContainer' container>
                  <Grid className='deliveryProofItem images' item>
                    <Grid container className='imagesContainer'>
                      <Grid item className='imagesItem addon'>
                        <Grid container className='imageItemContainer'>
                          <Grid item className='imageItem'>
                            <Typography className='heading'>Images</Typography>
                          </Grid>
                          <Grid item className='imageItem allImages'>
                            <Grid container className='allImagesContainer'>
                              <RcViewer className='viewerContainer'>{getCustomerImages()}</RcViewer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className='imagesItem sign'>
                        <Grid container className='imageItemContainer'>
                          <Grid item className='imageItem'>
                            <Typography className='heading'>Customer's Signature</Typography>
                          </Grid>
                          <Grid item className='imageItem allImages'>
                            <Grid container className='allImagesContainer sign'>
                              {deliveryInformation &&
                                deliveryInformation.deliveryProof &&
                                deliveryInformation.deliveryProof.signURL && (
                                  <RcViewer className='viewerContainer'>
                                    <img
                                      src={deliveryInformation.deliveryProof.signURL}
                                      className='customer-sign-img'
                                      alt='customer-signature'
                                    />
                                  </RcViewer>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className='deliveryProofItem comments' item>
                    <Grid container className='commentsContainer'>
                      <Grid item className='commentsItem head'>
                        <Typography className='heading'>Comments:</Typography>
                      </Grid>
                      <Grid item className='commentsItem details'>
                        <Typography className='desc'>
                          {deliveryInformation && deliveryInformation.deliveryProof
                            ? deliveryInformation.deliveryProof.comments
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className='consignmentHistory' item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls='consignment-history-content'
              id='consignment-history-header'
              className={classes.accordionSummary}
            >
              <Typography className='header'>Consignment History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='div' className='content'>
                <Timeline className={classes.timeline} align='left'>
                  {getHistoryTimelineItems()}
                </Timeline>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className='tabularSection items' item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls='consignment-history-content'
              id='consignment-history-header'
              className={classes.accordionSummary}
            >
              <Typography className='header'>Items Summary ({totalNumberOfItems})</Typography>
            </AccordionSummary>
            <AccordionDetails className='itemDiv'>
              <Typography component='div' className='content'>
                <Grid className='itemsContainer' container direction='column'>
                  <Grid className='item' item>
                    <Grid className='itemContainer header' container style={{padding: "16px"}}>
                         <Grid className='itemColumn item1' item xs={4}>
                            <Typography>Product Name</Typography>
                          </Grid>
                          <Grid className='itemColumn item2' item xs={2}>
                            <Typography>Rejected Qty</Typography>
                          </Grid>
                          <Grid className='itemColumn item2' item xs={2}>
                            <Typography>Shipped Qty</Typography>
                          </Grid>
                          <Grid className='itemColumn item2' item xs={2}>
                            <Typography>Ordered Qty</Typography>
                          </Grid>
                          <Grid className='itemColumn item3' item xs={2}>
                            <Typography>Unit Price</Typography>
                          </Grid>
                    </Grid>
                  </Grid>
                  {items?.length > 0 && getItems()}
                  {bundleProduct.length > 0 && getBundleProduct()}
                  {items?.length === 0 && bundleProductList?.length === 0 &&
                   (
                    <Typography>No Items to display</Typography>
                   )}
                </Grid>
                <Divider className='totalDivider'></Divider>
                <Grid className='paymentContainer' container direction='column'>
                  <Grid className='paymentItem' item>
                    <Grid container>
                      <Grid className='paymentItemColumn' item>
                        <Typography>Payment Method</Typography>
                      </Grid>
                      <Grid className='paymentItemColumn right' item>
                        <Typography>{metadata ? metadata.paymentType : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className='paymentItem' item>
                    <Grid container>
                      <Grid className='paymentItemColumn' item>
                        <Typography>Delivery Charges</Typography>
                      </Grid>
                      <Grid className='paymentItemColumn right' item>
                        <Typography>
                          {deliveryInformation && metadata
                            ? `${
                                fixDecimals(
                                  2,
                                  deliveryInformation.deliveryCost ? deliveryInformation.deliveryCost.toString() : ""
                                ) || ""
                              } ${(metadata && metadata.currency) || ""}`
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className='paymentItem' item>
                    <Divider className='totalDivider'></Divider>
                    <Grid container>
                      <Grid className='paymentItemColumn' item>
                        <Typography>
                          Grand Total<span className='vat'>(inclusive of VAT)</span>
                        </Typography>
                      </Grid>
                      <Grid className='paymentItemColumn right' item>
                        <Typography>
                          {metadata
                            ? `${fixDecimals(2, metadata.totalAmount ? metadata.totalAmount.toString() : "") || ""} ${
                                (metadata && metadata.currency) || ""
                              }`
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                  {metadata && (metadata.paymentType === AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.COD_TEXT || metadata?.paymentType  && isCardOnDelivery(metadata.paymentType)) ? (
                    <>
                      <Divider className="totalDivider"></Divider>
                      <Grid className='paymentContainer' container direction='column'>
                        <Grid className='paymentItem' item>
                          <Grid container>
                            <Grid className='paymentItemColumn' item>
                              <Typography>Amount to be Collected</Typography>
                            </Grid>
                            <Grid className='paymentItemColumn right' item>
                              <Typography>
                                {metadata
                                  ? `${
                                      fixDecimals(
                                        2,
                                        metadata.amountToCollect ? metadata.amountToCollect.toString() : ""
                                      ) || ""
                                    } ${(metadata && metadata.currency) || ""}`
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid className='paymentItem' item>
                          <Grid container>
                            <Grid className='paymentItemColumn' item>
                              <Typography>Collected Amount</Typography>
                            </Grid>
                            {revisedAmountAfterCalculation !== "" ? (
                              <Grid className='paymentItemColumn right' item>
                                <Typography>{`${revisedAmountAfterCalculation} ${
                                  (metadata && metadata.currency) || ""
                                }`}</Typography>
                              </Grid>
                            ) : (
                              <Grid className='paymentItemColumn right' item>
                                <Typography>{`${
                                  metadata.collectedAmount
                                    ? parseFloat(metadata.collectedAmount.toString()).toFixed(2)
                                    : "0.00"
                                } ${(metadata && metadata.currency) || ""}`}</Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className='tabularSection crates' item>
          <Accordion>
            <AccordionSummary
              expandIcon={<SvgIcon component={downArrowIcon} htmlColor={COLORS.COPY} />}
              aria-controls='crate-section-content'
              id='crate-section-content'
              className={classes.accordionSummary}
              onClick={() => handleCrateSummary()}
            >
              <Grid container direction='column'>
                <Typography className='header'>Crate Summary</Typography>
                <Typography className='header'>
                  <span className='smallHeading'>Total Crates</span>
                  {` : ${crateInfo.length} (Estimated ${
                    deliveryInformation ? deliveryInformation.estimatedCrates : ""
                  } Actual ${deliveryInformation ? deliveryInformation.totalCratesCount : ""})`}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='div' className='content' style={{width: "100%"}}>
                {crateInfo && crateInfo.length ? (
                  <Grid className='itemsContainer' container direction='column'>
                    <Grid className='itemContainer header' container>
                      <Grid className='itemColumn item1' item xs={4}>
                        <Typography>Crate ID</Typography>
                      </Grid>
                      <Grid className='itemColumn item2' item xs={4}>
                        <Typography>Number of Items</Typography>
                      </Grid>
                      <Grid className='itemColumn item3' item xs={4}>
                        <Typography>Staging Location</Typography>
                      </Grid>
                    </Grid>
                    {getCrateItems()}
                  </Grid>
                ) : (
                  <>
                    <Typography>No Crates to display</Typography>
                    {isAdmin ? (
                      <>
                        {(metadata && metadata.deliveryStatus === "NEW") ||
                        (metadata && metadata.deliveryStatus === "READY_TO_DISPATCH") ||
                        (metadata && metadata.deliveryStatus === "PREPARING") ||
                        (metadata && metadata.deliveryStatus === "PREPARED") ||
                        (metadata && metadata.deliveryStatus === "OUT_FOR_DELIVERY") ||
                        (metadata && metadata.deliveryStatus === "PACKING_COMPLETE") ? (
                          <Button
                            className='dialogBtn secondary'
                            style={{marginTop: "16px", width: "100%"}}
                            variant='contained'
                            onClick={() => handleCratesInfoFromAlecto(consignment)}
                          >
                            {AppConstants.BUTTONS.GET_CRATE_INFO}
                          </Button>
                        ) : null}
                        {!loading && isCrateInfo ? (
                          <>
                            {isCratesAvailable &&
                            cratesFromAlecto.crateNotAvailableOrders &&
                            cratesFromAlecto.crateNotAvailableOrders.length > 0 ? (
                              <div style={{padding: "24px 0px", color: "#DD2326"}}>No Crates Available</div>
                            ) : null}
                            {cratesFromAlecto.crateAvailableOrders &&
                            cratesFromAlecto.crateAvailableOrders.length > 0 ? (
                              <div>{getCrateItemsFromAlecto()}</div>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        </TabPanel>
        <TabPanel value={AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.TAB_VALUES_DETAIL.ORDER_LOG}>
            <Timeline className={classes.timeline} align='left'>
              {getOrderLogs()}
            </Timeline>
          </TabPanel>
      </TabContext>
      </Grid>
       
    
    );
  }

  useEffect(() => {
    if (Object.keys(consignmentDataAfterReplanning).length !== 0 || isPolled) {
      let consignmentCode = consignment?.consignmentCode;
      dispatch(
        fetchConsignmentHistoryAndProducts({
          params: consignmentCode
        })
      );
    }
  }, [consignmentDataAfterReplanning,isPolled, dispatch])

  useEffect(() => {
    if (consignment != null) {
      setIsCrateInfo(false);
      setAddressVerified(false);
      handleStatusHistoryAndProducts(consignment);
      if (isExpress) {
        handleDeliverySlotsForExpress(countryCode);
      }
    }
  }, [handleStatusHistoryAndProducts, handleDeliverySlotsForExpress, consignment, isExpress, countryCode]);
  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={null}
        handleClose={handleDialogClose}
      ></CustomDialog>
      <CustomDialog
        hideCloseIcon={true}
        open={openAddressPopup}
        PaperProps={confirmDialogPaperProps}
        title={getAddressDialogTitleContent()}
        content={getAddressDialogDetailsContent()}
        actions={getAddressDialogActions()}
        handleClose={handleAddressDialogClose}
      ></CustomDialog>
       {openConsignmentEditPopup && (
        <ConsignmentEdit
          isExpress={isExpress}
          items={items}
          bundleProduct={bundleProduct}
          open={openConsignmentEditPopup}
          consignment={consignment}
          shippingStatus={shippingStatus}
          closePopup={closeConsignmentEditPopup}
        />
      )}
      {renderInvoiceModal()}
    </>
  )
}

export default ConsignmentDetails;