import { Button, Grid, Link, MenuList, Paper, Popper, ClickAwayListener, Badge, SvgIcon } from '@material-ui/core';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import AppConstants from '../../constants/';
import MultiSearchComponent from '../MultiSearchComponent';
import { SelectItemProps } from '../SelectBox';
import { customHeaderUseStyles } from './CustomHeader.styles';
import { ComponentProps } from './DataTable';
import MenuItem from '@material-ui/core/MenuItem';
import { areDatesEqual } from 'utils/helpers.utils';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadIcon.svg'

interface CustomHeaderComponentProps extends ComponentProps {
  buttons?: Array<any>;
  searchTypes: Array<SelectItemProps>;
  searchType: any;
  activeFilters?: any;
  placeholderText: string;
  searchValue: string;
  getPlaceHolderText: () => void;
  setSearchTypeChange: (value: any) => void;
  searchCallback: (data: any, updatedSearchValue: string, filters?: any) => void;
  btnClickHandler?: (params: any) => void;
  clearAllFilterHandler?: () => void;
  defaultFilter?: any
  showHeader?: any;
  disableFilterCount?: boolean;
  showDataNextToSearchButton?: boolean;
  renderDataForSearchSection?: any;
}


const CustomHeaderComponent = (props: CustomHeaderComponentProps) => {
  const classes = customHeaderUseStyles();
  const {
    buttons,
    searchTypes,
    activeFilters,
    searchType,
    placeholderText,
    searchValue,
    showHeader,
    getPlaceHolderText,
    setSearchTypeChange,
    searchCallback,
    btnClickHandler,
    clearAllFilterHandler,
    defaultFilter,
    disableFilterCount,
    showDataNextToSearchButton = false,
    renderDataForSearchSection = null
  } = props;

  const [searchTextboxValue, setSearchTextboxValue] = useState(searchValue);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleSearchTypeChange = useCallback(
    (value: any) => {
      setSearchTypeChange(value);
      setSearchTextboxValue("");
    },
    [setSearchTypeChange]
  );

  const handleSearchBoxTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchTextboxValue(event.target.value);
    },
    []
  );

  const search = useCallback(
    () => {
      searchCallback({ [searchType]: searchTextboxValue }, searchTextboxValue, activeFilters)
    },
    [searchTextboxValue, searchType, activeFilters, searchCallback],
  )

  const handleKeyDownChange = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (searchTextboxValue && event.keyCode === 13) {
        search();
      }
    },
    [searchTextboxValue, search]
  )

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDateRangeChange = (dateCurrent: any, dateDefault:any) => {
    const isStartdateChnged = !areDatesEqual(dateCurrent.startDate, dateDefault?.startDate)
    const isEndDateChanged = !areDatesEqual(dateCurrent.endDate, dateDefault?.endDate);
    return (isStartdateChnged || isEndDateChanged)
  };



  const getActiveFiltersCount = useCallback(() => {
    let activeCount = 0;
    let dateRangeFilterChanged = false;
    if (activeFilters) {
      for (let filterKey in activeFilters) {
  
        const ignoreCount = activeFilters[filterKey]?.ignoreCount;
        if (!ignoreCount) {
          
          const filterValue =  activeFilters[filterKey]?.value ?? activeFilters[filterKey];
          
          if (filterKey === 'dateRange') {
            const dateDefault = defaultFilter[filterKey]?.value;
            dateRangeFilterChanged = handleDateRangeChange(filterValue, dateDefault);
            if (dateRangeFilterChanged) {
              activeCount++;
            }
          } else {
            if ((_.isArray(filterValue) && filterValue.length) || (!_.isArray(filterValue) && filterValue)) {
              activeCount++;
            }
          }

        }
      }
    }
    return activeCount;
  }, [activeFilters]);

  const handleButtonClick = (buttonObj: any) => {
    return btnClickHandler && btnClickHandler(buttonObj)
  }

  useEffect(() => {
    getPlaceHolderText();
  }, [searchType, getPlaceHolderText]);

  return (
    <Grid className={showHeader ? classes.root : classes.newDashboard} container>
      <Grid item>
        <MultiSearchComponent
          searchTypes={searchTypes}
          searchType={searchType}
          searchValue={searchTextboxValue}
          placeholderText={placeholderText}
          handleSearchTypeChange={handleSearchTypeChange}
          handleSearchBoxTextChange={handleSearchBoxTextChange}
          handleSearchBoxKeyDownChange={handleKeyDownChange}
          handleIconClick={search}
        />
      </Grid>
      {showDataNextToSearchButton && renderDataForSearchSection()}
      <Grid item className={classes.buttonContainer} style={{ display: "flex" }}>
       <Badge classes={{ badge: classes.customBadge }}  badgeContent={getActiveFiltersCount()} invisible={disableFilterCount}>
        <Link component="button" className="clearAllFilterBtn" underline="none" onClick={clearAllFilterHandler}>{AppConstants.BUTTONS.CLEAR_ALL_FILTERS}</Link>
       </Badge>
        {
          buttons && buttons.length ? (
            <Grid item className={classes.buttonGridSyle}>
              {buttons.map((buttonObj, index) => (
                buttonObj && buttonObj.name !== "colsettings" 
                  ? !buttonObj.hide 
                    ? buttonObj.name === AppConstants.BUTTONS.DOWNLOAD_ICON 
                      ? <SvgIcon key={`${buttonObj.name}-${index}`} className={classes.downloadIconStyle} onClick={() => handleButtonClick(buttonObj)} component={UploadIcon} />
                      : <Button key={`${buttonObj.name}-${index}`} disabled={buttonObj.disabled} className={[classes.button, buttonObj.classes].join(" ")} variant="contained" onClick={() => handleButtonClick(buttonObj)}>{buttonObj.displayName}</Button>
                    : null
                  :
                  <React.Fragment>
                    <Button className={classes.colSettings} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle}>
                      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="42" height="42" fill="#E5E5E5" />
                        <rect width="1440" height="1200" transform="translate(-1379 -210)" fill="white" />
                        <rect x="0.5" y="0.5" width="41" height="41" rx="8.5" fill="white" stroke="#D2D2D2" />
                        <circle cx="21" cy="15" r="2" fill="#4D4D4D" />
                        <circle cx="21" cy="27" r="2" fill="#4D4D4D" />
                        <circle cx="21" cy="21" r="2" fill="#4D4D4D" />
                      </svg>
                    </Button>
                    <Popper className="subMenuPoper" open={open} anchorEl={anchorRef.current} style={{ zIndex: 1000 }} transition placement='bottom-end' disablePortal>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="menu-list-grow1" onKeyDown={handleListKeyDown}>
                            {buttonObj.childElements && buttonObj.childElements.map((item: any, index: number) => (
                              <MenuItem key={`${item.name}-${index}`} onClick={() => btnClickHandler && btnClickHandler(item)}>Export Data</MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Popper>
                  </React.Fragment>
              ))}
            </Grid>
          ) : null
        }
      </Grid>
    </Grid>
  )
}

export default CustomHeaderComponent;