import React from "react";

import { Grid, Typography } from "@material-ui/core";

interface RenderPOSDetailsInterface {
  classes: any;
  rowData: any;
}

const RenderPOSDetails = (props: RenderPOSDetailsInterface) => {
  const { classes, rowData } = props;

  const getAddressInfo = () => {
    return rowData?.contactInfo?.address
      ? `${rowData?.contactInfo?.address?.streetName ? rowData?.contactInfo?.address?.streetName + ", " : ""}
                ${rowData?.contactInfo?.address?.city ? rowData?.contactInfo?.address?.city + ", " : ""}
                ${rowData?.contactInfo?.address?.area ? rowData?.contactInfo?.address?.area + ", " : ""}
                ${rowData?.contactInfo?.address?.countryName ? rowData?.contactInfo?.address?.countryName : ""}`
      : "";
  };

  return (
    <Grid container className={classes.renderPOSDetailsContainer}>
      <Grid item xs={12} className={classes.renderPOSDetailItem}>
        <Typography className={classes.renderPOSDetailSpanStyle}>
          {`Store : `}
          <Typography className={classes.renderPOSDetailBoldSpanStyle}>{`${rowData?.store}`}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.renderPOSDetailItem}>
        <Typography className={classes.renderPOSDetailSpanStyle}>
          {`Store Name :`}
          <Typography className={classes.renderPOSDetailBoldSpanStyle}>{`${rowData?.storeName}`}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.renderPOSDetailItem}>
        <Typography className={classes.renderPOSDetailSpanStyle}>
          {`Store Address :`}
          <Typography className={classes.renderPOSDetailBoldSpanStyle}>{`${getAddressInfo()}`}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.renderPOSDetailItem}>
        <Typography className={classes.renderPOSDetailSpanStyle}>
          {`Country :`}
          <Typography className={classes.renderPOSDetailBoldSpanStyle}>{`${rowData?.countryName}`}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.renderPOSDetailItem}>
        <Typography className={classes.renderPOSDetailSpanStyle}>
          {`Service Type :`}
          <Typography className={classes.renderPOSDetailBoldSpanStyle}>{`${rowData?.serviceType}`}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.renderPOSDetailItem}>
        <Grid container className={classes.renderPOSDetailItemContainer}>
          <Grid item xs={6}>
            <Typography className={classes.renderPOSDetailSpanStyle}>
              {`Latitude :`}
              <Typography className={classes.renderPOSDetailBoldSpanStyle}>{`${rowData?.contactInfo?.address?.latitude || 0}`}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.renderPOSDetailSpanStyle}>
              {`Longitude :`}
              <Typography className={classes.renderPOSDetailBoldSpanStyle}>{`${rowData?.contactInfo?.address?.longitude || 0}`}</Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderPOSDetails);
