import axios from "axios";

import { NetworkCallPayload } from "./networkHandler.types";
import { cWingsServiceBaseURL } from "../config/Api.config";
import { replaceUrlVariables } from "../utils/updateUrlParams";

const networkHandlerReporting = async (params: NetworkCallPayload) => {
  const { url, urlParams, ...restApiOptions } = params;
  let updateUrl = url;
  if (urlParams) {
    updateUrl = replaceUrlVariables(url, urlParams);
  }

  axios.defaults.headers.common = {
    Authorization: sessionStorage.getItem("token"),
  };

  axios.defaults.baseURL = cWingsServiceBaseURL;
  axios.defaults.headers.common["system-id"] = "CWINGS";

  return await axios.request({ url: updateUrl, ...restApiOptions });
};

export default networkHandlerReporting;
