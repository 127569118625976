import React from "react";
import { saveAs } from "file-saver";

import { Button, Grid } from "@material-ui/core";

import AppConstants from "../../constants/AppConstants";

interface RenderPOSQRCodeDetailsInterface {
  classes: any;
  rowData: any;
}

const RenderPOSQRCodeDetails = (props: RenderPOSQRCodeDetailsInterface) => {
  const { classes, rowData } = props;

  const dialogActionHandler = () => {
    if (rowData?.hubQRImagePath) {
      saveAs(rowData && rowData.hubQRImagePath, `${rowData.store}.jpg`);
    }
  };

  return (
    <Grid container className={classes.qrCodeMainContainer}>
      <Grid item xs={12} className={classes.qrCodeMainItem}>
        <img className={classes.qrImage} src={(rowData && rowData.hubQRImagePath) || ""} alt="icons" />
      </Grid>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12}>
          <Button className={`${classes.dialogButtonsStyle} dialogBtn secondary`} variant="contained" onClick={() => dialogActionHandler()}>
            {AppConstants.BUTTONS.DOWNLOAD_QR}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderPOSQRCodeDetails);
