import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../config/theme/baseTheme";
import { height } from "styled-system";

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0px 12px 0px 12px",
      height: "100%",
    },
    container: {
      flexDirection: "column",
      height: "100%",
      "& .userItem": {
        width: "100%",
        "&.heading": {
          paddingBottom: theme.spacing(2.5),
          "& p": {
            fontSize: 24,
            color: COLORS.BLACK,
            fontWeight: 600,
          },
        },
        "&.content": {
          backgroundColor: COLORS.WHITE,
          borderRadius: theme.spacing(1),
          height: "calc(100vh - 44px)",
          paddingTop: theme.spacing(1.5),
          "& .hubIdLink": {
            cursor: "pointer",
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: 12,
          },
          "& .serviceType": {
            color: "#2D2D2D",
            fontWeight: 500,
            fontSize: 12,
            textTransform: "capitalize",
          },
        },
      },
    },
    actionsHeader: {
      alignItems: "center",
      "& .actionItem": {
        cursor: "pointer",
        margin: "auto 0px",
        "& .icon": {
          padding: theme.spacing(0.25),
          margin: theme.spacing(0, 1, 0, 1),
          fill: COLORS.PRIMARY_MAIN,
        },
      },
    },
    // Dialog
    dialogPaperPropsRoot: {
      margin: 0,
      marginLeft: "auto",
      maxHeight: "none",
      height: "100vh",
      width: 680,
    },
    titleContainer: {
      padding: theme.spacing(0.75, 0, 0, 0),
    },
    titleContainerItem: {
      padding: 0,
      margin: "auto 0px",
    },
    titleSpanStyle: {
      color: COLORS.MINE_SHAFT,
      fontWeight: 600,
      fontSize: 18,
      textTransform: "uppercase",
    },
    //
    renderPOSDetailsContainer: {
      padding: theme.spacing(0, 1, 0, 1),
      margin: "auto 0px",
      background: COLORS.BLACK_SQUEEZE,
      borderRadius: 8,
      border: "none",
    },
    renderPOSDetailItem: {
      display: "flex",
      justifyContent: "flex-start",
      padding: theme.spacing(1, 1, 0, 1),
      margin: "auto 0px",
      color: COLORS.MINE_SHAFT,
      fontWeight: 500,
      fontSize: 14,
    },
    renderPOSDetailSpanStyle: {
      display: "flex",
      color: COLORS.MINE_SHAFT,
      fontWeight: 500,
      fontSize: 14,
    },
    renderPOSDetailBoldSpanStyle: {
      color: COLORS.MINE_SHAFT,
      fontWeight: 600,
      fontSize: 14,
      margin: "auto 0px auto 8px",
    },
    renderPOSDetailItemContainer: {
      padding: theme.spacing(0, 0, 1, 0),
      margin: "auto 0px",
    },
    //
    dialogButtonsContainer: {
      justifyContent: "center",
      "& .dialogBtn": { "&.primary": { marginLeft: theme.spacing(1) } },
    },
    dialogButtonsStyle: {
      width: "100%",
    },
    //
    hqrCodeDialogPaperPropsRoot: {
      width: 500,
      height: 500,
      margin: "auto",
      borderRadius: theme.spacing(1),
    },
    qrCodeMainContainer: {
      padding: theme.spacing(1, 0, 2, 0),
      margin: "auto 0px",
    },
    qrCodeMainItem: {
      padding: theme.spacing(2),
      margin: "auto 0px",
      width: "100%",
      height: "250px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    qrImageContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      alignItems: "center",
    },
    qrImage: {
      width: "200px",
      height: "200px",
    },
    // Edit Section
    switchSectionContainer: {
      display: "flex",
      justifyContent: "flex-start",
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
    switchSectionSpanStyle: {
      color: COLORS.MINE_SHAFT,
      fontWeight: 400,
      fontSize: 14,
      margin: "auto 8px auto 0px",
    },
    valueSectionContainer: {
      padding: theme.spacing(1, 0, 0, 0),
      margin: theme.spacing(0),
    },
    editScreenHeaderSpanStyle: {
      color: COLORS.MINE_SHAFT,
      fontWeight: 400,
      fontSize: 14,
      margin: "0px 0px 8px 0px",
    },
    //
    circularGridConatiner: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "400px",
      alignItems: "center",
    },
    // Next to Search section data
    searchSectionContainer: {
      width: "auto",
      height: "auto",
      margin: "auto 0px",
    },
    searchSectionlSpanStyle: {
      display: "flex",
      color: COLORS.MINE_SHAFT,
      fontWeight: 400,
      fontSize: 14,
      margin: "auto 0px auto 32px",
    },
    searchSectionBoldSpanStyle: {
      color: COLORS.MINE_SHAFT,
      fontWeight: 600,
      fontSize: 14,
      margin: "auto 0px auto 4px",
    },
  })
);
