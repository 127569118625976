import AppConstants from "../constants/AppConstants";

export const emptySessionStorage = () => {
  sessionStorage.setItem("token", "");
  sessionStorage.setItem("username", "");
  sessionStorage.setItem("name", "");
  sessionStorage.setItem("role", "[]");
  sessionStorage.setItem("images", "");
  sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
};

export const setSessionStorage = (payload: any) => {
  const token = `Bearer ${payload.jwtToken}`;
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("username", payload?.username);
  sessionStorage.setItem("name", payload?.name);
  sessionStorage.setItem("role", JSON.stringify(payload?.roles));
  sessionStorage.setItem("images", JSON.stringify(payload?.images || ""));
  sessionStorage.setItem(AppConstants.COUNTRY_CODE, payload?.countryCode);
};
