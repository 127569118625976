import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import networkHandler from "../../../network/networkHandler";
import networkHandlerReporting from "../../../network/networkHandlerReporting";

import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { countObjectProperties, isInputAnArray, isNull, transformHubWithNameAndValueFromSelectValues, transformSelectValues } from "../../../utils/helpers.utils";
import { CommonState } from "./commonSlice.types";

import AppConstants from "../../../constants";

export const countryCodeUpdate = createAsyncThunk("Common/CountryCodeUpdate", async ({ countryCode }: { countryCode: any }, { rejectWithValue }) => {
  try {
    const countryRequest = { url: "fulfilment/country/url", method: EApiMiddlewareMethods.GET } as any;
    countryRequest.headers = { countryISOCode: countryCode };
    const { data: countryData } = await networkHandlerReporting(countryRequest);
    let countryUrl = countryData?.url?.wings ? countryData.url.wings : countryData?.countryUrl ? countryData.countryUrl : "";
    sessionStorage.setItem(AppConstants.COUNTRY_URL, countryUrl);
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
  return countryCode;
});

export const hubCodeUpdate = createAsyncThunk("Common/HubCodeUpdate", (hubCode: string[]) => {
  return hubCode;
});

export const updateMapDirections = createAsyncThunk("Common/MapDirectionsUpdate", (directions: any) => {
  return directions;
});

export const fetchCommonDropdownValues = createAsyncThunk(
  "Common/FetchCommonDropdownValues",
  async (
    {
      countryCode,
    }: {
      countryCode: any;
    },
    { rejectWithValue }
  ) => {
    let params = countryCode ? countryCode : sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    try {
      const request = {
        url: `filter/all/${params}`,
        method: EApiMiddlewareMethods.GET,
      } as any;
      const { data } = await networkHandler(request, false, true);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserDetailsDropdownValues = createAsyncThunk("Common/FetchUserDetailsDropdownValues", async (params, { rejectWithValue }) => {
  try {
    const request = {
      url: `user/hubs`,
      method: EApiMiddlewareMethods.GET,
    } as any;
    const { data } = await networkHandler(request, false, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchCountryConfigDetils = createAsyncThunk("Common/fetchCountryConfigDetils", async ({ activeStatus }: any, { rejectWithValue }) => {
  try {
    const request = { url: `config?active=${activeStatus}`, method: EApiMiddlewareMethods.GET } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchCountryHubDetailsDetils = createAsyncThunk("Common/fetchCountryHubDetailsDetils", async ({ countryCode, deliveryType = null }: any, { rejectWithValue }) => {
  try {
    let params = countryCode ? countryCode : sessionStorage.getItem(AppConstants.COUNTRY_CODE);
    const request = { url: `fulfilment/hub/country/${params}`, method: EApiMiddlewareMethods.GET } as any;
    if (!isNull(deliveryType)) {
      request.url = `${request.url}?deliveryType=${deliveryType}`;
    }
    const { data } = await networkHandlerReporting(request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchCwingsReportCountriesList = createAsyncThunk("Common/fetchCwingsReportCountriesList", async (_, { rejectWithValue }) => {
  try {
    const request = { url: `fulfilment/country/all`, method: EApiMiddlewareMethods.GET } as any;
    const { data } = await networkHandlerReporting(request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const initialState: CommonState = {
  loading: false,
  error: "",
  errorCode: "",
  countryCode: sessionStorage.getItem(AppConstants.COUNTRY_CODE) || AppConstants.COUNTRY_OBJ.value,
  hubCode: JSON.parse(sessionStorage.getItem(AppConstants.HUB_CODE) || "[]") || [],
  mapRouteDirections: {},
  hubData: {},
  commonDropdowns: { countriesList: [], hubCodeList: [], hubNameList: [], deliverySlotList: [] },
  userDetailsDropdowns: { countriesList: [], hubList: [], hubListWithCodeAndName: [] },
  routeConfigDetails: {},
  driverTripConfigDetails: {},
  hubRecordList: {},
  hubRecordListWithCodeAndName: {},
  cwingsReportCountriesList: [],
};

const commonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchCommonDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchCommonDropdownValues.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        let hubCodeList: any = {};
        let hubNameList: any = {};
        let deliverySlotList: any = {};
        if (payload.hubMap) {
          Object.keys(payload.hubMap).filter((country: any) => {
            hubCodeList[country] = transformSelectValues(payload.hubMap[country], "code");
            hubNameList[country] = transformSelectValues(payload.hubMap[country], "value");
            return true;
          });
        }
        if (payload.deliverySlot) {
          Object.keys(payload.deliverySlot).filter((country: any) => {
            deliverySlotList[country] = transformSelectValues(payload.deliverySlot[country]);
            return true;
          });
        }
        state.commonDropdowns = {
          countriesList: transformSelectValues(payload.countries),
          hubCodeList: hubCodeList,
          hubNameList: hubNameList,
          deliverySlotList: deliverySlotList,
        };
      })
      .addCase(fetchCommonDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchUserDetailsDropdownValues.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchUserDetailsDropdownValues.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.hubData = payload.hubMap || {};
        let hubList: any = {};
        let hubListWithCodeAndName: any = {};
        if (payload.hubMap) {
          Object.keys(payload.hubMap).filter((country: any) => {
            hubList[country] = transformSelectValues(payload.hubMap[country]);
            hubListWithCodeAndName[country] = transformHubWithNameAndValueFromSelectValues(payload.hubMap[country]);
            return true;
          });
        }
        state.userDetailsDropdowns = {
          countriesList: transformSelectValues(payload.countries),
          hubList: hubList,
          hubListWithCodeAndName: hubListWithCodeAndName,
        };
      })
      .addCase(fetchUserDetailsDropdownValues.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(countryCodeUpdate.fulfilled, (state, action) => {
        const { payload } = action;
        sessionStorage.setItem(AppConstants.COUNTRY_CODE, payload);
        sessionStorage.setItem(AppConstants.PLANNING_CONSTANTS.HUB_FOR_ROUTES, "");
        state.countryCode = payload;
      })
      .addCase(hubCodeUpdate.fulfilled, (state, action) => {
        const { payload } = action;
        sessionStorage.setItem(AppConstants.HUB_CODE, JSON.stringify(payload));
        state.hubCode = payload;
      })
      .addCase(updateMapDirections.fulfilled, (state, action) => {
        const { payload } = action;
        state.mapRouteDirections = payload;
      })
      /** fetchCountryConfigDetils */
      .addCase(fetchCountryConfigDetils.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.routeConfigDetails = {};
        state.driverTripConfigDetails = {};
      })
      .addCase(fetchCountryConfigDetils.fulfilled, (state, action) => {
        state.loading = false;
        const { payload } = action;
        if (payload?.ROUTE && Object.keys(payload?.ROUTE).length > 0) {
          state.routeConfigDetails = payload?.ROUTE || {};
        }
        if (payload?.DRIVER_TRIP && Object.keys(payload?.DRIVER_TRIP).length > 0) {
          state.driverTripConfigDetails = payload?.DRIVER_TRIP || {};
        }
      })
      .addCase(fetchCountryConfigDetils.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchCountryHubDetailsDetils.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(fetchCountryHubDetailsDetils.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { countryCode },
          },
        } = action;
        if (payload?.results && countObjectProperties(payload.results) > 0) {
          let hubRecordList: any = [];
          let hubRecordListWithCodeAndName: any = [];
          if (countryCode && payload?.results[countryCode] && isInputAnArray(payload?.results[countryCode])) {
            hubRecordList = transformSelectValues(payload?.results[countryCode]);
            hubRecordListWithCodeAndName = transformHubWithNameAndValueFromSelectValues(payload?.results[countryCode]);
            state.hubRecordList[countryCode] = hubRecordList;
            state.hubRecordListWithCodeAndName[countryCode] = hubRecordListWithCodeAndName;
          }
        }
        state.loading = false;
      })
      .addCase(fetchCountryHubDetailsDetils.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(fetchCwingsReportCountriesList.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.cwingsReportCountriesList = [];
      })
      .addCase(fetchCwingsReportCountriesList.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload && Array.isArray(payload)) {
          state.cwingsReportCountriesList = transformSelectValues(payload);
        }
        state.loading = false;
      })
      .addCase(fetchCwingsReportCountriesList.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      });
  },
});

export default commonSlice.reducer;
