import { generateRandomNumber } from "../utils/helpers.utils";

const StoreManagementConstant = {
  STORE_MANAGEMENT_CONSTANT: {
    TABLE_WRAPPER_DATA: {
      title: "Hubs",
      HeaderData: [
        { field: "id", headerName: "Id", hide: true },
        { field: "store", headerName: "Store Id", width: 120 },
        { field: "storeName", headerName: "Store", width: 150 },
        { field: "latLong", headerName: "Latitude/Longitude", width: 150 },
        { field: "countryName", headerName: "Country", width: 120 },
        {
          field: "serviceType",
          headerName: "Service Type",
          width: 150,
          filter: true,
          filterObj: {
            type: "autocomplete",
            fieldKey: "serviceType",
            fieldName: "serviceType",
            fieldFullName: "serviceType",
            multiple: true,
            key: `autoComplete-serviceType-${generateRandomNumber(0, 1000)}`,
            label: "Service Type",
            items: [
              { name: "STANDARD", value: "STANDARD" },
              { name: "EXPRESS", value: "EXPRESS" },
            ],
          },
        },
        { field: "area", headerName: "Delivery Areas", width: 120 },
        { field: "actions", headerName: "Actions", width: 100 },
      ],
      headerButtons: [],
      loadMore: { name: "loadMore", disabled: false, pageSize: 20 },
      defaultFiltersObj: {
        serviceType: {
          key: "serviceType",
          value: [],
          fieldName: "serviceType",
        },
      },
      SEARCH_TYPES: [
        { name: "Store Id", value: "store" },
        { name: "Store Name", value: "storeName" },
      ],
    },
  },
};

export default StoreManagementConstant;
