import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { transformSelectValues } from "../../utils/helpers.utils";
import { StoreManagementState } from "./StoreManagementSlice.type";
import { cWingsServiceBaseURL, expressBaseURL } from "../../config/Api.config";

import AppConstants from "../../constants";
import newNetworkHandler from "../../network/newNetworkHandler";

export const fetchHubRecords = createAsyncThunk("storeManagement/fetchHubRecords", async (payload: any, { rejectWithValue }) => {
  try {
    const request = { url: "/fulfilment/dashboard/hub/filter", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await newNetworkHandler(request, cWingsServiceBaseURL);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const updateStoreRecordsSlice = createAsyncThunk("storeManagement/updateStoreRecordsSlice", async (payload: any, { rejectWithValue }) => {
  try {
    const request = { url: "/fulfilment/dashboard/hub", method: EApiMiddlewareMethods.PUT, data: payload } as any;
    const { data } = await newNetworkHandler(request, cWingsServiceBaseURL);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const regenerateQR = createAsyncThunk("storeManagement/RegenerateQR", async ({ params }: { params: any }, { rejectWithValue }) => {
  try {
    const request = { url: `hub/qr/generate/${params}`, method: EApiMiddlewareMethods.GET } as any;
    const { data } = await newNetworkHandler(request, expressBaseURL);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const resetRegenerateQR = createAsyncThunk("storeManagement/resetRegenerateQR", () => {
  return false;
});

const initialState: StoreManagementState = {
  loading: false,
  errorCode: "",
  error: "",
  storeData: { elements: [], pageNumber: 0, totalElements: 0 },
  regeneratedQRCode: null,
  isQRRegenerated: false,
};

const StoreManagementSlice = createSlice({
  name: "storeManagement",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchHubRecords.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.storeData = { elements: [], pageNumber: 0, totalElements: 0 };
      })
      .addCase(fetchHubRecords.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        if (payload?.results) {
          state.storeData = {
            ...payload,
            elements: payload?.results?.elements,
            pageNumber: payload?.results?.pageNumber || 0,
            totalElements: payload?.results?.totalElements || 0,
            totalPages: payload?.results?.totalPages || 0,
          };
        }
      })
      .addCase(fetchHubRecords.rejected, (state, action) => {
        state.loading = false;
        const errorPayload: any = action?.payload;
        state.errorCode = errorPayload ? errorPayload?.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload && errorPayload?.message ? errorPayload?.message || "" : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(updateStoreRecordsSlice.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(updateStoreRecordsSlice.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreRecordsSlice.rejected, (state, action) => {
        state.loading = false;
        const errorPayload: any = action?.payload;
        state.errorCode = errorPayload ? errorPayload?.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload && errorPayload?.message ? errorPayload?.message || "" : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** regenerateQR */
      .addCase(regenerateQR.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
        state.regeneratedQRCode = null;
        state.isQRRegenerated = false;
      })
      .addCase(regenerateQR.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.regeneratedQRCode = payload && payload.results.path;
        state.isQRRegenerated = true;
      })
      .addCase(regenerateQR.rejected, (state, action) => {
        state.loading = false;
        const errorPayload: any = action?.payload;
        state.errorCode = errorPayload ? errorPayload?.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload && errorPayload?.message ? errorPayload?.message || "" : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /**resetRegenerateQR */
      .addCase(resetRegenerateQR.fulfilled, (state, action) => {
        state.errorCode = "";
        state.error = "";
        state.regeneratedQRCode = null;
        state.isQRRegenerated = false;
      });
  },
});

export default StoreManagementSlice.reducer;
