import AppConstants from "constants/AppConstants";

export const addNRPageAction = (actionName: string, featureName: string) => {
  const env = process.env.REACT_APP_NEW_RELIC_ENV || "unknown";
  const fullActionName = `${actionName}-${env}`;
  
  if (window.newrelic) {
    window.newrelic.addPageAction(fullActionName, {
      featureName,
      country: sessionStorage.getItem(AppConstants.COUNTRY_CODE),
      username: sessionStorage.getItem("username"),
      timestamp: Date.now(),
    });
  }
};