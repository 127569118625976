import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
//common components
import { AppState } from "../../config/redux/reducers";
import AppConstants from "../../constants";
import CustomerCommunExpress from "./CommunicationExpress";
import CustomerCommunStandard from "./CommunicationStandard";
import NoDataComponent from "../../StagingArea/NoDataComponent";
import {
  RowData,
} from "../../common/GridWrapper/DataTable";
import {
  fetchFormFilterDropdown,
  fetchCountryAndArea,
  resetData 
} from "../redux/customerCommSlice";
//redux
//Material UI Components
import { Grid, Button } from "@material-ui/core";
//styles
import { useStyles } from "../customerComm.styles";
//assets
import {
  isUndefined,
  transformListOrObjIntoKeyValue,
  transformSelectValuesBasedOnKey,
} from "../../utils/helpers.utils";
import FilterForm from "../../common/GridWrapper/filterFormWrapper";
import SendCommunication from "../SendCommunication";
import { fetchCountryHubDetailsDetils } from "../../config/redux/common/commonSlice";

interface ExpressProps {
  match?: any;
  handleTypeChange :(value:string) => void
  changeTabAfterSuccess?:any
}

export interface SelectItemProps {
  orderType?: string;
  hubCode?: string;
}
const CustomerCommun = (props: ExpressProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {changeTabAfterSuccess, handleTypeChange} = props

  const { filterFormDropDown, hubDetail } = useSelector((state: AppState) => state.customerCommunication);
  const { countryCode, hubRecordListWithCodeAndName } = useSelector((state: AppState) => state.common);

  const [resetAllFlag, setResetAllFlag] = useState(false);
  const [openSendComm, setOpenSendComm] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([] as RowData[]);
  const [showTable, setShowTable] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [deSelectCheckbox, setDeSelectCheckbox] = useState(false);
  const [seed, setSeed] = useState(1);
  const [dropDownSelect, setDropDownSelect] = useState<SelectItemProps>({});
  const [ordersTableWrapperData, setOrdersTableWrapperData] = useState({
    ...AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
      .TABLE_WRAPPER_DATA,
    defaultFiltersObj: {
      ...AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
        .TABLE_WRAPPER_DATA.defaultFiltersObj,
      dateRange: {
        ...AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
          .TABLE_WRAPPER_DATA.defaultFiltersObj.dateRange,
        value: {
          startDate: moment().format(AppConstants.DATE_FORMAT),
          endDate: moment().format(AppConstants.DATE_FORMAT),
        },
      },
    },
  });

  const setOrderTableWrapperDate = (headerData:any) =>{
    setOrdersTableWrapperData({
      ...ordersTableWrapperData,
      FilterFormData: headerData,
    });
  }

  const fetchDropDownForTypeOfOrder= () => {
    let headerData = ordersTableWrapperData.FilterFormData.map(
      (header: any) => {
        if (header) {
          if (header.fieldName === "orderType") {
            header.items =
              AppConstants.CUSTOMER_COMM_CONSTANTS.CONSIGNMENTS_DATA
                .TABLE_WRAPPER_DATA.orderType || [];
          }
        }
        return header;
      }
    );
    setOrderTableWrapperDate(headerData)
  };

  const fetchDropDownbasedOnHub = () => {
    let headerData = ordersTableWrapperData.FilterFormData.map(
      (header: any) => {
        if (header) {
          if (header.fieldName === "deliveryStatus") {
            const status = transformListOrObjIntoKeyValue(
              filterFormDropDown.statuses
            );
            header.items = status || [];
          }
          if (header.fieldName === "deliveryAreas") {
                        const area = transformListOrObjIntoKeyValue(
              filterFormDropDown.deliveryAreas
            );
            header.items = area || [];
          }
        }
        return header;
      }
    );
    setOrderTableWrapperDate(headerData)
   
  };

  const fetchDropDownbasedOnOrdertype = (key: any, val?: any) => {
    let headerData = ordersTableWrapperData.FilterFormData.map((header: any) => {
      if (header) {
        if (key == "hub" && header.fieldName === "hub") {
          header.items = [];
          if(!isUndefined(hubRecordListWithCodeAndName[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode])){
            header.items = hubRecordListWithCodeAndName[sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode] || [];
          }          
        }
        if (key == "zones" && header.fieldName === "zones") {
          header.items = transformSelectValuesBasedOnKey(hubDetail?.zones[val], "name", "name") || [];
        }
      }
      return header;
    });
    setOrderTableWrapperDate(headerData)
  };

  const resetDropDown = () =>{
    let headerData = ordersTableWrapperData.FilterFormData.map((header: any) => {
      if (header) {
        if(header.fieldName !== "orderType"){
          header.items = [];
        }
      }
      return header;
    });
    setOrderTableWrapperDate(headerData)
  }

  const resetDropDownExceptHub = () =>{
    let headerData = ordersTableWrapperData.FilterFormData.map((header: any) => {
      if (header) {
        if(header.fieldName !== "orderType" && header.fieldName !== "hub" ){
          header.items = [];
        }
      }
      return header;
    });
    setOrderTableWrapperDate(headerData)
  }

  const resetScreen=()=>{
    setOrderType("")
    setSeed(Math.random());
    setShowTable(false);
    setSelectedRoutes([]);
    dispatch(resetData())
    resetDropDown()
    setDropDownSelect({})
  }


  useEffect(() => {
    fetchDropDownForTypeOfOrder();
    return () => {
      dispatch(resetData())
      resetDropDown()
    };
  }, []);

  useEffect(() => {
    resetScreen()
  }, [countryCode]);


  useEffect(() => {
    if (Object.keys(filterFormDropDown).length !== 0) {
      fetchDropDownbasedOnHub();
    }
  }, [filterFormDropDown]);

  useEffect(() => {
    if (hubRecordListWithCodeAndName && dropDownSelect.hasOwnProperty("orderType")) {
      fetchDropDownbasedOnOrdertype("hub");
    }
  }, [hubRecordListWithCodeAndName, dropDownSelect]);

  const fetchCallbackOnOrder = (name: string, value: any) => {
    setDropDownSelect({ ...dropDownSelect, [name]: value });
    if (name === "orderType" && value) {
      setOrderType(value);
      handleTypeChange(value)
      setResetAllFlag(true);
      dispatch(resetData())
      resetDropDown()
      setShowTable(false)
      setSelectedRoutes([]);
      dispatch(fetchCountryHubDetailsDetils({ countryCode, deliveryType : value }));
    }
    if (name === "hub" && value.length && value[0].value !== AppConstants.SELECT_NONE.value) {
      fetchDropDownbasedOnOrdertype("zones", value[0].value);
      const payload = {filter: value[0].value, countryCode: countryCode, orderType: orderType};
      dispatch(fetchFormFilterDropdown({ params: payload }));
    }
  };

  const handleFormFilters = (filter: any) => {
    setShowTable(true);
    setFilters(filter);
  };

  const onRowSelection = (row: any) => {
    setDeSelectCheckbox(false);
    setSelectedRoutes(row);
  };

  const handleClose = () => {
    setOpenSendComm(false);
  };

  const handleProcced = () => {
    setOpenSendComm(true);
  };

  const handleCancel = () => {
    setSelectedRoutes([]);
    setDeSelectCheckbox(true);
  };

  const restFilterForm = (val:boolean) =>{
    setResetAllFlag(val)
    resetDropDownExceptHub()
    setDropDownSelect({})
  }

  return (
    <>
      {ordersTableWrapperData.FilterFormData.length && (
        <Grid className={classes.filterSection} item>
          <Grid container direction="column">
            <Grid className="filtersContent" container>
               <FilterForm
                filterForm={ordersTableWrapperData.FilterFormData}
                defaultValues={ordersTableWrapperData.defaultFiltersObjForm}
                fetchDataCallback={fetchCallbackOnOrder}
                handleFormFilters={handleFormFilters}
                diableClick={orderType ? false : true}
                resetAllFilter={resetAllFlag}
                setResetAllFlag={setResetAllFlag}
                key={seed}
                orderTyp={orderType}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container className={classes.customerCommunication}>
        <Grid item xs={12}>
          {showTable ? (
            <>
              {orderType == "EXPRESS" ? (
                <CustomerCommunExpress 
                filter={filters} 
                onRowSelection={onRowSelection}
                restFilterForm={restFilterForm}
                deSelectCheckbox={deSelectCheckbox}
                selectedRoutes={selectedRoutes}
                handleCancel={handleCancel}
                />
              ) : (
                orderType === "STANDARD" && (
                  <CustomerCommunStandard
                    filter={filters}
                    onRowSelection={onRowSelection}
                    restFilterForm={restFilterForm}
                    deSelectCheckbox={deSelectCheckbox}
                    selectedRoutes={selectedRoutes}
                    handleCancel={handleCancel}
                    />
                )
              )}
            </>
          ) : (
            <Grid className={classes.noData}><NoDataComponent title={"No Data Available"} /></Grid>
          )}
        </Grid>

        {selectedRoutes.length > 0 && (
          <Grid className={classes.buttonsGroup} item>
            <Button className="dialogBtn secondary" variant="contained" onClick={() => handleCancel()}>
              {AppConstants.BUTTONS.CANCEL}
            </Button>
            <Button className="dialogBtn primary" variant="contained" onClick={() => handleProcced()}>
              {AppConstants.BUTTONS.PROCEED}
            </Button>
          </Grid>
        )}
        {selectedRoutes.length > 0 && (
          <SendCommunication
            open={openSendComm}
            closePopup={handleClose}
            selectedValue={selectedRoutes}
            changeTabAfterSuccess={changeTabAfterSuccess}
            orderType={orderType}
          />
        )}
      </Grid>
    </>
  );
};

export default CustomerCommun;
