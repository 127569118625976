import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  PopoverOrigin,
  Select,
  SelectProps,
  ListSubheader,
  TextField,
  InputAdornment,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { useStyles, anchorOriginDefault } from "./SelectBox.styles";
import { ReactComponent as downArrowIcon } from "./../../assets/icons/down-arrow.svg";
import AppConstants from "../../constants";
import { ReactComponent as searchIcon } from "./../../assets/icons/search-icon-small.svg";
import { isEqualArrays } from "utils/helpers.utils";

export interface SelectItemProps {
  name?: string;
  value: any;
  countryCode?: any;
}

interface SelectBoxProps extends SelectProps {
  handleChange?: (value: any) => void;
  handleFormikChange?: (e: React.ChangeEvent<any>) => void;
  items: Array<SelectItemProps>;
  helperText?: any;
  hideIconComponent?: boolean;
  menuPropsList?: string;
  menuPaperProps?: string;
  anchorOrigin?: PopoverOrigin | undefined;
  reset?: boolean;
  resetAll?: boolean;
  showSelectNone?: boolean;
  enableSearch?: boolean;
  classes?: any;
  customWidthSearchBar?: boolean;
}
const SelectBox = (props: SelectBoxProps) => {
  const classes = useStyles();

  const {
    handleChange,
    handleFormikChange,
    value,
    multiple,
    id,
    label,
    items,
    hideIconComponent,
    menuPaperProps,
    menuPropsList,
    anchorOrigin,
    className,
    reset,
    resetAll,
    showSelectNone,
    error,
    helperText,
    fullWidth,
    enableSearch,
    customWidthSearchBar,
    ...rest
  } = props;
  const [itemsDdn, setItemsDdn] = useState(items && items.map((item) => item.value));
  const [itemsPrevValues, setItemsPrevValues] = useState<SelectItemProps[]>([]);
  const [ddnValue, setDdnValue] = useState<any>(value || AppConstants.SELECT_NONE.value);
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const containsText = (text: any, searchText: any) => {
    return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };

  const displayedOptions = useMemo(() => {
    return items.filter((option) => containsText(option.name, searchText));
  }, [searchText]);

  const getMenuItems = () => {
    const result = searchText ? displayedOptions : items;
    return result && result.length
      ? result.map((item, index) => (
          <MenuItem key={`${item.value}-${index}`} value={item.value}>
            {multiple && <Checkbox size="small" color="primary" checked={ddnValue.indexOf(item.value) > -1} />}
            <ListItemText primary={item.name} />
          </MenuItem>
        ))
      : null;
  };

  const handleDdnChange = useCallback(
    (event: React.ChangeEvent<SelectItemProps>, child: React.ReactNode) => {
      let selected = event.target.value;

      if (multiple) {
        const selectAllIdx = selected.indexOf(AppConstants.SELECT_ALL.value);
        const selectAllPreviousIdx = ddnValue.indexOf(AppConstants.SELECT_ALL.value);
        const selectNoneIdx = selected.indexOf(AppConstants.SELECT_NONE.value);
        if (selectNoneIdx > -1) {
          selected = selected.filter((item: any) => item !== AppConstants.SELECT_NONE.value);
        }
        if (selectAllIdx > -1) {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = selected.filter((item: any) => item !== AppConstants.SELECT_ALL.value);
            } else {
              selected = itemsDdn.slice();
            }
          }
        } else {
          if (selected.length < itemsDdn.length) {
            if (selectAllPreviousIdx > -1) {
              selected = [];
            } else if (selected.length === itemsDdn.length - 1) {
              selected = itemsDdn.slice();
            }
          }
        }
        if (!selected.length) {
          selected = [AppConstants.SELECT_NONE.value];
        }
      }

      if (Array.isArray(selected)) {
        selected.filter((item: any) => item);
        if (!selected.includes(undefined)) {
          event.target.value = selected;
          setDdnValue(selected);
          handleChange && handleChange(selected);
          handleFormikChange && handleFormikChange(event);
        }
      } else {
        event.target.value = selected;
        setDdnValue(selected);
        handleChange && handleChange(selected);
        handleFormikChange && handleFormikChange(event);
      }
    },
    [ddnValue, itemsDdn, multiple, handleChange, handleFormikChange]
  );

  useEffect(() => {
    if (!isEqualArrays(items, itemsPrevValues, "value")) {
      if (multiple) {
        setItemsDdn([AppConstants.SELECT_ALL.value, ...items.map((item) => item.value)]);
        setDdnValue([AppConstants.SELECT_ALL.value, ...items.map((item) => item.value)]);
      } else {
        setDdnValue(value);
        showSelectNone && setItemsDdn([AppConstants.SELECT_NONE.value, ...items.map((item) => item.value)]);
      }
    }
    setItemsPrevValues(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    // console.log("value", value, reset);
    if (reset) {
      if (multiple) {
        setDdnValue(itemsDdn);
        handleChange && handleChange(itemsDdn);
      } else {
        setDdnValue(value);
        handleChange && handleChange(value);
      }
    } else {
      setDdnValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (resetAll) {
      if (multiple) {
        setDdnValue(itemsDdn);
        handleChange && handleChange(itemsDdn);
      } else {
        setDdnValue(value);
        handleChange && handleChange(itemsDdn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAll]);

  const getChips = (selected: any) => {
    if (multiple) {
      if (selected.indexOf(AppConstants.SELECT_ALL.value) > -1) {
        return AppConstants.SELECT_ALL.name;
      }
      if (selected.indexOf(AppConstants.SELECT_NONE.value) > -1) {
        return AppConstants.SELECT_NONE.name;
      }
      return Array.isArray(selected) && selected?.length > 0 ? (
        <Typography className={classes.selectedTextStyle}>{`${selected.length} selected`}</Typography>
      ) : (
        <div className={classes.chips}>
          {(selected as string[]).map((value) => {
            const item: SelectItemProps =
              value === AppConstants.SELECT_ALL.value ? AppConstants.SELECT_ALL : items.filter((i) => i.value === value).length ? items.filter((i) => i.value === value)[0] : { name: "", value: "" };
            return <Chip key={value} label={item.name} size="small" className={classes.chip} />;
          })}
        </div>
      );
    } else {
      const item: SelectItemProps = items && items.filter((i) => i.value === selected).length ? items.filter((i) => i.value === selected)[0] : AppConstants.SELECT_NONE;
      return item.name;
    }
  };

  const close = () => {
    setIsOpen(false);
    setSearchText("");
  };

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl} error={error} fullWidth={fullWidth}>
        {label && <InputLabel id={`${id}-${label}`}>{label}</InputLabel>}
        <Select
          {...rest}
          displayEmpty
          fullWidth={fullWidth}
          className={`${className || ""} ${isOpen ? "open " : ""}${label ? "hasLabel " : ""}`}
          ref={anchorEl}
          labelId={label ? `${id}-${label}` : ""}
          multiple={multiple}
          value={ddnValue}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={close}
          onChange={handleDdnChange}
          renderValue={getChips}
          IconComponent={hideIconComponent ? () => <></> : downArrowIcon}
          MenuProps={{
            disablePortal: true,
            classes: {
              list: menuPropsList || classes.menuPropsList,
              paper: menuPaperProps || classes.menuPropsPaper,
            },
            autoFocus: false,
            anchorEl: anchorEl.current,
            getContentAnchorEl: null,
            anchorOrigin: anchorOrigin || anchorOriginDefault,
          }}
        >
          {enableSearch && (
            <ListSubheader className={`${classes.searchBox} ${customWidthSearchBar && classes.customWidthSearch}`} onClickCapture={stopImmediatePropagation} onKeyDown={(e) => e.stopPropagation()}>
              <TextField
                variant="outlined"
                size="small"
                value={searchText}
                autoFocus
                placeholder="Type to search..."
                fullWidth
                className="text-search"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" size="small">
                        <SvgIcon component={searchIcon} viewBox="0 0 16 16" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ListSubheader>
          )}
          {(!searchText || searchText.length === 0) && multiple ? (
            <MenuItem className="selectAll" key={AppConstants.SELECT_ALL.value} value={AppConstants.SELECT_ALL.value}>
              <Checkbox size="small" color="primary" checked={ddnValue.indexOf(AppConstants.SELECT_ALL.value) > -1} />
              <ListItemText primary={AppConstants.SELECT_ALL.name} />
            </MenuItem>
          ) : (
            showSelectNone && (
              <MenuItem className="selectAll" key={AppConstants.SELECT_NONE.value} value={AppConstants.SELECT_NONE.value}>
                <ListItemText primary={AppConstants.SELECT_NONE.name} />
              </MenuItem>
            )
          )}
          {getMenuItems()}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default SelectBox;
