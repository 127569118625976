import networkHandler from "../../../network/networkHandler";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AllRoutesSliceInitialState, commonFetchInitialState } from "../utils/AllRouteConstant";
import { transformSelectValues } from "../../../utils/helpers.utils";
import { EApiMiddlewareMethods } from "../../../network/networkHandler.types";
import { API_RESPONSE_STATUS } from "../../../constants/CommonConstants";
import { addNRPageAction } from "utils/newRelicUtils";
import AppConstants from "../../../constants";
import { getDropdownsData } from "mocks/routePlanning/response.transforms";
import { BulkDeletedRouteInterface } from "RoutePlanning/redux/planningSlice.types";

export const createDraftAllRoutes = createAsyncThunk("AllRoutesPlanning/CreateDraftAllRoute", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.CREATE_DRAFT_ALL_ROUTE);
    const request = { url: "route/draft/create", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const createAndEditAllRoutes = createAsyncThunk("AllRoutesPlanning/CreateAndEditRoute", async ({ payload, editMode }: { payload: any; editMode: boolean }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.EDIT_ROUTE);
    const request = { url: "route/edit", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const publishAllRoutes = createAsyncThunk("AllRoutesPlanning/PublishAllRoutes", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.PUBLISH_ALL_ROUTE);
    const request = { url: "route/publish", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchAllPlannedRoutes = createAsyncThunk("AllRoutesPlanning/FetchAllPlannedRoutes", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.FETCH_ALL_PLANNED);
    const request = { url: "route/planned-route", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchAllUnPlannedRoutes = createAsyncThunk("AllRoutesPlanning/fetchAllUnPlannedRoutes", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.FETCH_ALL_UNPLANNED);
    const request = { url: "route/unplanned-route", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const createRemoveOrderFromRoute = createAsyncThunk("AllRoutesPlanning/createRemoveOrderFromRoute", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.UPDATE_ORDER_IN_ROUTE);
    const request = { url: "order/update-order-route-status", method: EApiMiddlewareMethods.PUT, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchExistingRouteToAssignToOrder = createAsyncThunk("AllRoutesPlanning/fetchExistingRouteToAssignToOrder", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.ADD_ORDER_TO_ROUTE);
    const request = { url: "route/add-order/list-routes", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const createNewSubRoute = createAsyncThunk("AllRoutesPlanning/createNewSubRoute", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.SUB_ROUTE.NAME, AppConstants.NR_FEATURES.SUB_ROUTE.FEATURES.CREATE_SUB_ROUTE);
    const request = { url: "route/sub-route", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const createAndEditRoute = createAsyncThunk("AllRoutesPlanning/CreateAndEditRoute", async ({ payload, editMode }: { payload: any; editMode: boolean }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.CREATE_AND_EDIT_ROUTE);
    const request = { url: "route/edit", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchAllDrivers = createAsyncThunk("AllRoutesPlanning/FetchAllDrivers", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.FETCH_ALL_DRIVERS);
    const request = { url: "assignee/drivers", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchAllHelpers = createAsyncThunk("AllRoutesPlanning/FetchAllHelpers", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.FETCH_ALL_HELPERS);
    const request = { url: "assignee/helpers", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchAllVehicles = createAsyncThunk("AllRoutesPlanning/FetchAllVehicles", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.FETCH_ALL_VEHICLES);
    const request = { url: "vehicle/load", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const publishRoutes = createAsyncThunk("AllRoutesPlanning/PublishRoutes", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.PUBLISH_ROUTE);
    const request = { url: "route/publish", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const createDraftRoute = createAsyncThunk("AllRoutesPlanning/CreateDraftRoute", async ({ params }: { params: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.PUBLISH_ROUTE);
    const request = { url: "route/draft/create", method: EApiMiddlewareMethods.POST, data: params } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const loadAllSlots = createAsyncThunk("AllRoutesPlanning/LoadAllSlots", async ({ params }: { params: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.LOAD_ALL_SLOTS);
    const request = { url: "route/draft/load/slots", method: EApiMiddlewareMethods.POST, data: params } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const updateConsignmentStatus = createAsyncThunk("AllRoutesPlanning/UpdateOrderStatus", async ({ payload }: { payload: any }, { rejectWithValue }) => {
  try {
    addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.UPDATE_CONSIGNMENT_STATUS);
    const request = { url: "order/updateRouteStatus", method: EApiMiddlewareMethods.PUT, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const bulkDeleteRoute = createAsyncThunk("AllRoutesPlanning/BulkDeleteRoute", async ({ payload }: { payload: BulkDeletedRouteInterface }, { rejectWithValue }) => {
  addNRPageAction(AppConstants.NR_FEATURES.ALL_ROUTE.NAME, AppConstants.NR_FEATURES.ALL_ROUTE.FEATURES.BULK_DELETE);
  try {
    const request = { url: "v2/route/delete", method: EApiMiddlewareMethods.POST, data: payload } as any;
    const { data } = await networkHandler(request, false, true, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const resetCreateRouteSuccess = createAsyncThunk("AllRoutesPlanning/ResetCreateRouteSuccess", () => {
  return false;
});

const initialState: AllRoutesSliceInitialState = {
  loading: false,
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  plannedRoutes: {},
  unPlannedRoutes: {},
  removedRouteData: {},
  existingRoutesList: {},
  orderStatuses: [],
  updatedRoute: null,
  routeCreateSuccess: false,
  currentSlot: null,
  driversList: [],
  helpersList: [],
  vehiclesList: [],
  publishedRoutesErrors: [],
  publishedRoutes: [],
  draftRoute: {},
  routeDiscardSuccess: false,
  slotwisePlannedRoutes: [],
  consignmentStatusList: [],
  slotsList: [],
  zoneList: [],
  deletedRoute: null,
  bulkDeletedRoute: null,
};

const allRoutesSlice = createSlice({
  name: "AllRoutesPlanning",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchAllPlannedRoutes.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.plannedRoutes = {};
      })
      .addCase(fetchAllPlannedRoutes.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.plannedRoutes = payload;
      })
      .addCase(fetchAllPlannedRoutes.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /** fetchAllUnPlannedRoutes */
      .addCase(fetchAllUnPlannedRoutes.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.unPlannedRoutes = {};
      })
      .addCase(fetchAllUnPlannedRoutes.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.orderStatuses = transformSelectValues(payload.deliveryStatuses || []);
        state.unPlannedRoutes = payload;
      })
      .addCase(fetchAllUnPlannedRoutes.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /** createRemoveOrderFromRoute */
      .addCase(createRemoveOrderFromRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.removedRouteData = {};
      })
      .addCase(createRemoveOrderFromRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.removedRouteData = payload;
      })
      .addCase(createRemoveOrderFromRoute.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /* fetchExistingRouteToAssignToOrder */
      .addCase(fetchExistingRouteToAssignToOrder.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.existingRoutesList = {};
      })
      .addCase(fetchExistingRouteToAssignToOrder.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.existingRoutesList = payload;
      })
      .addCase(fetchExistingRouteToAssignToOrder.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /* createNewSubRoute */
      .addCase(createNewSubRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(createNewSubRoute.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(createNewSubRoute.rejected, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.error = payload;
      })
      /** createAndEditRoute */
      .addCase(createAndEditRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createAndEditRoute.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { editMode },
          },
        } = action;
        state.loading = false;
        if (editMode) {
          state.updatedRoute = payload;
        } else {
          state.routeCreateSuccess = true;
        }
        state.currentSlot = null;
        sessionStorage.setItem("routeId", "");
        sessionStorage.setItem("extraConsignments", "[]");
      })
      .addCase(createAndEditRoute.rejected, (state, action) => {
        const {
          payload,
          meta: {
            arg: { editMode },
          },
        } = action;
        const errorPayload: any = payload;
        state.loading = false;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        if (editMode) {
          state.updatedRoute = null;
        } else {
          state.routeCreateSuccess = false;
        }
      })
      /** fetchAllDrivers */
      .addCase(fetchAllDrivers.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllDrivers.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.error = "";
        state.driversData = payload;
        const drivers = getDropdownsData(payload);
        state.driversList = transformSelectValues(drivers);
      })
      .addCase(fetchAllDrivers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** fetchAllHelpers */
      .addCase(fetchAllHelpers.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllHelpers.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.error = "";
        state.helpersData = payload;
        const helpers = getDropdownsData(payload);
        state.helpersList = transformSelectValues(helpers);
      })
      .addCase(fetchAllHelpers.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** fetchAllVehicles */
      .addCase(fetchAllVehicles.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAllVehicles.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.error = "";
        state.vehiclesData = payload;
        const vehicles = getDropdownsData(payload);
        state.vehiclesList = transformSelectValues(vehicles);
      })
      .addCase(fetchAllVehicles.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** publishRoutes */
      .addCase(publishRoutes.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.publishedRoutesErrors = [];
      })
      .addCase(publishRoutes.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        if (payload.publishErrorDto && payload.publishErrorDto.length) {
          state.publishedRoutesErrors = payload.publishErrorDto;
          state.publishedRoutes = payload.publishedRoutes && payload.publishedRoutes.length ? payload.publishedRoutes : [];
        } else {
          state.publishedRoutes = payload.publishedRoutes && payload.publishedRoutes.length ? payload.publishedRoutes : [];
        }
      })
      .addCase(publishRoutes.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.publishedRoutes = [];
        state.publishedRoutesErrors = [];
      })
      /** createDraftRoute */
      .addCase(createDraftRoute.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.draftRoute = {};
      })
      .addCase(createDraftRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.error = "";
        state.routeCreateSuccess = false;
        state.updatedRoute = null;
        state.routeDiscardSuccess = false;
        state.currentSlot = null;
        state.slotwisePlannedRoutes = [];
        state.draftRoute = payload;
        state.consignmentStatusList = transformSelectValues(payload.deliveryStatuses || []);
        sessionStorage.setItem("routeId", payload.route && payload.route.id);
        sessionStorage.setItem("extraConsignments", "[]");
      })
      .addCase(createDraftRoute.rejected, (state, action) => {
        const {
          payload,
          meta: {
            arg: { params },
          },
        } = action;
        const errorPayload: any = payload;
        state.loading = false;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.routeCreateSuccess = false;
        state.updatedRoute = null;
        state.routeDiscardSuccess = false;
        state.currentSlot = null;
        state.draftRoute = {};
        state.slotwisePlannedRoutes = [];
        if (!params.editMode) {
          sessionStorage.setItem("routeId", "");
          sessionStorage.setItem("extraConsignments", "[]");
        }
      })
      /** loadAllSlots */
      .addCase(loadAllSlots.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(loadAllSlots.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.error = "";
        state.slotwisePlannedRoutes = [];
        state.slotsData = payload;
        state.currentSlot = payload.currentSlot && payload.currentSlot.length ? payload.currentSlot[0] : {};
        state.slotsList = transformSelectValues(payload.allSlots);
        state.zoneList = transformSelectValues(payload.deliveryZones);
      })
      .addCase(loadAllSlots.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.currentSlot = null;
        state.slotsList = [];
        state.draftRoute = {};
        state.slotwisePlannedRoutes = [];
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /**  updateConsignmentStatus*/
      .addCase(updateConsignmentStatus.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateConsignmentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(updateConsignmentStatus.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /**  bulkDeleteRoute*/
      .addCase(bulkDeleteRoute.pending, (state) => {
        state.deletedRoute = null;
        state.loading = true;
        state.error = "";
      })
      .addCase(bulkDeleteRoute.fulfilled, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.bulkDeletedRoute = payload;
      })
      .addCase(bulkDeleteRoute.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.error = errorPayload ? AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.BULK_DELETE_ERROR_MSG : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      /** resetCreateRouteSuccess */
      .addCase(resetCreateRouteSuccess.fulfilled, (state, action) => {
        const { payload } = action;
        state.routeCreateSuccess = payload;
      });
  },
});

export default allRoutesSlice.reducer;
