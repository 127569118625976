import axios from "axios";
import { NetworkCallPayload } from "./networkHandler.types";
import { cWingsServiceBaseURL, reportBaseURL } from "../config/Api.config";
import { replaceUrlVariables } from "../utils/updateUrlParams";

const networkDefaultSetting = (urlString: string) => {
  axios.defaults.baseURL = urlString || reportBaseURL;
  axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");
  axios.defaults.headers.common["system-id"] = "CWINGS";
};

const newNetworkHandler = async (params: NetworkCallPayload, urlString: string) => {
  const { url, urlParams, ...restApiOptions } = params;
  let updateUrl = url;
  if (urlParams) {
    updateUrl = replaceUrlVariables(url, urlParams);
  }
  networkDefaultSetting(urlString);
  return await axios.request({ url: updateUrl, ...restApiOptions });
};

export default newNetworkHandler;
