import _ from "lodash";

import AppConstants from "../../constants";
import { StoreDataResponse, StoreInterface } from "./StoreManagementInterface";
import { isUndefined } from "../../utils/helpers.utils";

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
  let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
  const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
  const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
  if (selectAllIdx > -1) {
    currentValue = [];
  }
  if (selectNoneIdx > -1) {
    currentValue = [];
  }
  return currentValue;
};

export const createFetchHubRecordsPayload = (payload: any, data?: any, isLoadMore?: boolean, countryCode?: any) => {
  payload.countryCode = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
  payload.attributes = [{ key: "serviceType", value: ["STANDARD", "EXPRESS"] }];
  if (payload?.serviceType && payload.serviceType.value && payload.serviceType.value?.length !== 3) {
    const attrValue = getFilterAttributeValue(payload.serviceType.value);
    if (attrValue?.length !== 0) {
      payload.attributes = [{ key: "serviceType", value: attrValue }];
    }
    delete payload.serviceType;
  }
  delete payload.hubId;
  delete payload.hubName;
  delete payload.pageNumber;
  delete payload.reset;
  return payload;
};

export const designPosListToShow = (response: StoreDataResponse) => {
  const transformedArray: any[] = [];
  response.elements.forEach((posObject: StoreInterface) => {
    transformedArray.push({
      ...posObject,
      id: posObject.store,
      latLong: `${posObject?.contactInfo?.address?.latitude || 0}, ${posObject?.contactInfo?.address?.longitude || 0}`,
      countryName: posObject?.contactInfo?.address?.countryName || "",
      area: posObject?.contactInfo?.address?.area || "",
      serviceType: posObject?.deliveryType?.length > 0 ? posObject?.deliveryType.join(", ") : "",
    });
  });
  return [...transformedArray];
};

export const createUpdateStoreRecordsSlicePayload = (newState: any) => {
  let payload = _.cloneDeep(newState);
  if (payload?.category) {
    const selectAllIdx = payload.category.indexOf(AppConstants.SELECT_ALL.value);
    if (selectAllIdx !== -1) {
      payload.category.splice(selectAllIdx, 1);
    }
  }

  if (payload?.preferredVehicle) {
    const selectAllIdx = payload.preferredVehicle.indexOf(AppConstants.SELECT_ALL.value);
    if (selectAllIdx !== -1) {
      payload.preferredVehicle.splice(selectAllIdx, 1);
    }
  }

  if (!isUndefined(payload?.autoAssignment)) {
    payload.isAutoAssignment = payload?.autoAssignment || false;
    delete payload.autoAssignment;
  }

  if (!isUndefined(payload?.initialAssignmentAllowed)) {
    payload.isInitialAssignmentAllowed = payload?.initialAssignmentAllowed || false;
    delete payload.initialAssignmentAllowed;
  }

  return payload;
};
