import { createStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "../../config/theme/baseTheme";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px 24px 0px 24px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    titleSpanStyle: {
      "& .MuiTypography-body1": {
        color: COLORS.TEXT_PRIMARY,
        fontWeight: 600,
        fontSize: 20,
        marginTop: "8px",
      },
    },
    buttons: {
      textAlign: "center",
    },
  });
