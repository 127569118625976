import AppConstants from "./AppConstants";
import RouteConstants from "./RouteConstants";
import PlanningConstants from "./PlanningConstants";
import LoginConstants from "./LoginConstants";
import UsersConstants from "./UsersConstants";
import CodReconciliationConstants from "./CodReconciliationConstants";
import DashboardConstants from "./PerformanceConstants";
import TrackingConstants from "./TrackingConstants";
import HubConstants from "./HubConstants";
import HubDashboardConstants from "./HubDashboardConstant";
import ClickAndCollectConstants from "./ClickAndCollectConstants";
import ValetTrolleyConstants from "./ValetTrolleyConstants";
import CarrefourHomeDeliveryConstants from "./CarrefourHomeDeliveryConstants";
import DelveConstants from "./DelveConstants";
import CustomerCommConstants from "./CustomerCommunications";
import ExpressConstants from "./ExpressConstants";
import POSConstants from "./POSConstants";
import DriversConstants from "./DriversConstants";
import CODPortalConstants from "./CODPortalConstants";
import StdCodPortalConstants from "./StdCodPortalConstants";
import StagingAreaConstants from "./StagingAreaConstants";
import StoreManagementConstant from "./StoreManagementConstant";

export default {
  ...AppConstants,
  ...RouteConstants,
  ...PlanningConstants,
  ...UsersConstants,
  ...CodReconciliationConstants,
  ...DashboardConstants,
  ...LoginConstants,
  ...TrackingConstants,
  ...HubConstants,
  ...HubDashboardConstants,
  ...ClickAndCollectConstants,
  ...ValetTrolleyConstants,
  ...CarrefourHomeDeliveryConstants,
  ...DelveConstants,
  ...ExpressConstants,
  ...POSConstants,
  ...DriversConstants,
  ...CODPortalConstants,
  ...StdCodPortalConstants,
  ...StagingAreaConstants,
  ...CustomerCommConstants,
  ...StoreManagementConstant,
};
